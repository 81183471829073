import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

//import cryptoWalletColumns from "./cryptoWalletColumns"

// Table Data
const productData = [
  { id: 1, idno: "#SK3226", pdate: "03 Mar, 2020", type:"Subscribe", amount: "0.00413 SVC" },
  { id: 2, idno: "#SK3336", pdate: "03 Mar, 2020", type:"Change", amount: "0.0125 SVC" },
  { id: 3, idno: "#SK3226", pdate: "13 Jun, 2020", type:"Unsubscribe", amount: "0 SVC" },
  { id: 4, idno: "#SK3226", pdate: "03 Mar, 2020", type:"Subscribe", amount: "0.00413 SVC" },
]

class SubscriptionHistory extends Component {
  // const data = {
  //   columns: cryptoWalletColumns,
  //   rows: walletHistory,
  // }

  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          dataField: 'created_at',
          text: 'Date',
          formatter: (cellContent, row) => (
            new Date(row?.created_at)?.toLocaleString()
          )
        },
        {
          dataField: 'invoice',
          text: 'ID'
        },
        {
          dataField: 'subscription_plan',
          text: 'Plan',
          formatter: (cellContent, row) => (
            row.subscription_plan?.name
          )
        },
        {
          dataField: 'amount',
          text: 'Amount'
        },
        {
          dataField: 'status',
          text: 'Status',
          formatter: (cellContent, row) => (
            <span
              className={row?.status == 'paid' ? 'text-success' : 'text-danger'}
              style={{ textTransform: 'capitalize' }}
            >{row?.status}</span>
          )
        },
      ]
    }
  }


  render() {
    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Subscription History</h4>
          {/*
          <ul className="nav nav-tabs nav-tabs-custom">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Buy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                Sell
              </NavLink>
            </NavItem>
          </ul>
          */}

          {this.props.invoices?.length ? (
            <div className="mt-4 table-responsive">
              <BootstrapTable keyField='_id'
                data={this.props.invoices}
                columns={this.state.columns} />
            </div>
          ) : (
            <p className="text-muted">You have no transaction history yet.</p>
          )}

        </CardBody>
      </Card>
    )
  }
}

SubscriptionHistory.propTypes = {
  history: PropTypes.array,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
  invoices: PropTypes.any,
  plans: PropTypes.any,
}

export default SubscriptionHistory
