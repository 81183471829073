import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import logo from "../assets/images/atlantis_logo_white.png"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">

          <Link to="/bot-list" className="mt-2">
            <div className="artemis-logo">
              <img src={logo} className="img-fluid" width="90" />
            </div>
          </Link>

          <div data-simplebar className="h-100 pt-5">
            <SidebarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(Sidebar))
