import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Breadcrumbs from "navigation/Breadcrumb"

import CardUser from "./BotDetailComponents/card-user"
import CardWelcome from "./BotDetailComponents/card-welcome"
import WalletBalance from "./BotDetailComponents/wallet-balance"
import OverView from "./BotDetailComponents/overview"
import Transactions from "./BotDetailComponents/transactions"
import Notifications from "./BotDetailComponents/notifications"
import BuySell from "./BotDetailComponents/buy-sell"
import moment from "moment"
import { Chart } from "react-google-charts";

import {
  getBots,
  getAPIs,
  updateBot,
  getOpenOrders,
  deleteBot,
  getSelectedCoinBal
} from "store/actions"


// Bitcoin Chart
const series1 = [
  { name: "BTC", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
]
const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#f1b44c"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

// Ethereum Chart
const series2 = [
  { name: "ETH", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
]
const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#3452e1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

// LiteCoin Chart
const series3 = [
  { name: "LTC", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
]
const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#50a5f1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

class BotDetail extends Component {
  constructor(props) {
    console.log("constructor called");
    super(props)
    this.state = {
      loadingOrders: true,

      reports: [
        {
          title: "Annual Interest Pace",
          icon: "mdi mdi-bitcoin",
          color: "warning",
          value: "$ 57,86.76",
          arrow: 'mdi-arrow-up text-success',
          desc: "+ 0.0012 ( 0.2 % )",
          series: series1,
          options: options1,
        },
        {
          title: "Total Contractor",
          icon: "mdi mdi-ethereum",
          color: "primary",
          arrow: 'mdi-arrow-down text-danger',
          value: "$ 2,077.53",
          desc: "- 4.102 ( 0.1 % )",
          series: series2,
          options: options2,
        },
        {
          title: "BP",
          icon: "mdi mdi-litecoin",
          color: "info",
          arrow: 'mdi-arrow-up text-success',
          value: "$ 225.01",
          desc: "+ 1.792 ( 0.1 % )",
          series: series3,
          options: options3,
        },
        {
          title: "Trading Volume",
          icon: "mdi mdi-litecoin",
          color: "info",
          arrow: 'mdi-arrow-up text-success',
          value: "$ 225.01",
          desc: "+ 1.792 ( 0.1 % )",
          series: series3,
          options: options3,
        },
      ],
      updatingBot: null,
      selectedCoinBal: null,
      selectedCoinBalLoading: true,
      msgToRestartBot: null
    }

    this.onGetBotsSuccess = this.onGetBotsSuccess.bind(this)
    this.onGetBotsError = this.onGetBotsError.bind(this)
    this.onGetOpenOrdersSuccess = this.onGetOpenOrdersSuccess.bind(this)
    this.onGetOpenOrdersError = this.onGetOpenOrdersError.bind(this)

    this.upDownColors = this.upDownColors.bind(this)

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.onToggleDeleteBot = this.onToggleDeleteBot.bind(this)
    this.onConfirmDeleteBot = this.onConfirmDeleteBot.bind(this)
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
    this.onDeleteError = this.onDeleteError.bind(this)
  }

  componentDidMount() {
    const { bots, onGetBots, exchangeAPIs, onGetAPIs, onGetOpenOrders, onGetSelectedCoinBal } = this.props;
    // if (!bots || !bots.length) {
    onGetBots()
    // }
    // if (!exchangeAPIs || !exchangeAPIs.length) {
    onGetAPIs()
    // }
    onGetOpenOrders(this.props.match.params.id, this.onGetOpenOrdersSuccess, this.onGetOpenOrdersError)
    onGetSelectedCoinBal(this.props.match.params.id, (res) => {
      // console.log("onGetSelectedCoinBal res ", res)
      if (res.status == "success") {
        if (res.data) {
          this.setState({ selectedCoinBal: res.data, selectedCoinBalLoading: false })
        } else {
          this.setState({ msgToRestartBot: res.msg, selectedCoinBalLoading: false })
        }
      }
    }, () => {
      this.setState({ selectedCoinBalLoading: false })

    })
  }

  onGetBotsSuccess(response) {
    // console.log(response);
  }

  onGetBotsError(response) {
    // console.log(response);
  }

  onGetOpenOrdersSuccess(response) {
    // console.log(response);
    this.setState({ loadingOrders: false })
  }

  onGetOpenOrdersError(response) {
    // console.log(response);
    this.setState({ loadingOrders: false })
  }


  upDownColors(text) {
    let value = text.split('(')[0];
    value = value.replace('+', '');
    value = value.replace('- ', '-');
    value = parseFloat(value);

    if (value > 0) return '#20c671';
    else if (value < 0) return '#eb3737';
    else return '#000';
  }


  onToggleDeleteBot(bot) {
    // console.log("onToggleDeleteBot called", bot);
    this.setState({ updatingBot: bot });

    this.toggleDeleteModal();
  }

  toggleDeleteModal() {
    this.setState({ deleteModalVisible: !this.state.deleteModalVisible });
  }

  onConfirmDeleteBot() {
    this.props.onDeleteBot(this.state.updatingBot, this.onDeleteSuccess, this.onDeleteError)
    this.setState({ deleteModalVisible: false })
  }

  onDeleteSuccess(response) {
    this.setState({ success: 'Bot successfully deleted.' })
    this.props.history.push('/bot-list')
  }

  onDeleteError(response) {
    if (!response) {
      this.setState({ error: 'Connection Error' })
    }
    else {
      this.setState({ error: response.error })
    }
  }

  deleteBotModal() {
    return (
      <Modal
        isOpen={this.state.deleteModalVisible}
        toggle={this.toggleDeleteModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Delete Bot</h5>
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <h5>This is a reminder that once bot is deleted, subscription fee will not be refunded.</h5>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.onConfirmDeleteBot}
          >
            Delete
          </button>
        </div>
      </Modal>
    )
  }

  render() {
    const bot = this.props.bots?.find(bot => bot._id == this.props.match.params.id)
    const api = bot?.api_id ? this.props.exchangeAPIs.find(api => api._id == bot.api_id._id) : null
    // console.log("this.props ", this.props);

    let pieDataStart = [];
    let pieDataEnd = [];
    let daysFromStart = 0;
    if (this.state.selectedCoinBal) {
      if (Object.prototype.hasOwnProperty.call(this.state.selectedCoinBal, "start")) {

        if (this.state.selectedCoinBal.start.msg) {
          pieDataStart.push(["COINS", "Amount", { type: 'string', role: 'tooltip' }])
          // pieDataStart.push(["0", 0, this.state.selectedCoinBal.start.msg])
          bot.trade_coins.map((item) => {
            pieDataStart.push([item.coin_type, 1, this.state.selectedCoinBal.start.msg])
          });

          pieDataEnd.push(["COINS", "Amount", { type: 'string', role: 'tooltip' }])
          bot.trade_coins.map((item) => {
            let current = this.state.selectedCoinBal.current[item.coin_type];
            let labelStart = current.amount.toFixed(3) + " " + item.coin_type;
            pieDataEnd.push([labelStart, current.in_usdt, current.in_usdt.toString() + " USDT"])

          })
        } else {

          pieDataStart.push(["COINS", "Amount", { type: 'string', role: 'tooltip' }])
          pieDataEnd.push(["COINS", "Amount", { type: 'string', role: 'tooltip' }])
          bot.trade_coins.map((item) => {
            let start = this.state.selectedCoinBal.start[item.coin_type];
            let labelStart = start.amount.toFixed(3) + " " + item.coin_type;
            pieDataStart.push([labelStart, start.in_usdt, start.in_usdt.toString() + " USDT"])
            // console.log("pieDataStart ", pieDataStart);

            let end = this.state.selectedCoinBal.current[item.coin_type];
            let labelEnd = end.amount.toFixed(3) + " " + item.coin_type + " (" + (((end.amount - start.amount) * 100) / end.amount).toFixed(3) + "%)";
            pieDataEnd.push([labelEnd, end.in_usdt, end.in_usdt.toString() + " USDT"])
            // console.log("pieDataEnd ", pieDataEnd);

          })
          daysFromStart = moment.duration(moment(this.state.selectedCoinBal.current.date, "YYYY-MM-DD").diff(moment(this.state.selectedCoinBal.start.date, "YYYY-MM-DD"))).asDays() + 1;
        }

      } else {
        pieDataEnd.push(["COINS", "Amount", { type: 'string', role: 'tooltip' }])
        bot.trade_coins.map((item) => {
          let current = this.state.selectedCoinBal.current[item.coin_type];
          let labelStart = current.amount.toFixed(3) + " " + item.coin_type;
          pieDataEnd.push([labelStart, current.in_usdt, current.in_usdt.toString() + " USDT"])

        })
      }
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Talmud | {bot && bot.name}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="My Bots" breadcrumbItem={bot && bot.name} parent="/bot-list" />

            <Col xl="12">
              <div className="d-flex align-items-center mb-3">
                <h4 className="card-title" style={{ marginRight: 'auto' }}>Bot Information</h4>

                {/*<div className="ms-auto d-inline-flex align-items-center justify-content-center">
                  <i className={`bx font-size-24 ${bot?.is_initialised ? 'bx-check-circle text-success' : 'bx-x-circle text-danger'}`} />
                  <span className={`fw-bold ms-1 ${bot?.is_initialised ? 'text-success' : 'text-danger'}`}>
                    {bot?.is_initialised ? 'Initialized' : 'Not Initialized'}
                  </span>
                </div>*/}

                <div className="ms-4 d-inline-flex align-items-center justify-content-center">
                  <p className="mb-0">Expiration Date: {bot?.subscription_expiry ? (new Date(bot?.subscription_expiry)?.toLocaleString()) : 'Not Subscribed'}</p>
                </div>

                <p className="mb-0 ms-4">Status: <span className={`fw-bold ${bot?.status ? 'text-success' : 'text-danger'}`}>{
                  bot?.status == null ? 'Loading...' : (bot?.status ? 'Running' : 'Stopped')
                }</span></p>
              </div>

              <Card>
                <CardBody>
                  <Row className="mt-3">
                    <Col xs="6" sm="4">
                      <div>
                        <p className="mb-2">Delta</p>
                        <h5>{bot?.delta ? (bot.delta * 100) + '%' : '--'}</h5>
                      </div>
                    </Col>
                    <Col xs="6" sm="4">
                      <div>
                        <p className="mb-2">Coin Pairs</p>
                        <h5>{bot?.pairs?.join(', ') || '--'}</h5>
                      </div>
                    </Col>
                    <Col xs="6" sm="4">
                      <div>
                        <p className="mb-2">Trading Fee</p>
                        <h5>{bot?.tradingfee ? bot.tradingfee + '%' : '--'}</h5>
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-5">
                    <h6>Exchange: {api?.name ? api.name + ` (${api.exchange_id?.name})` : '--'}</h6>
                  </div>
                  <div className="mt-3 mb-3">
                    <h6>Created On: {bot?.created_date ? new Date(bot.created_date).toLocaleString() : '--'}</h6>
                  </div>

                </CardBody>
              </Card>
            </Col>


            {
              this.state.selectedCoinBalLoading || this.state.msgToRestartBot ?
                <Card>
                  <CardBody >
                    {
                      this.state.msgToRestartBot ?
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="ms-2">{this.state.msgToRestartBot}</span>
                        </div>
                        :
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="spinner-border text-danger" role="status"></div>
                          <span className="ms-2">{this.props.t("Loading balances...")}</span>
                        </div>
                    }
                  </CardBody>
                </Card>
                :
                null
            }
            {
              bot && this.state.selectedCoinBal ?
                <Row style={{ marginBottom: 24 }}>
                  {Object.prototype.hasOwnProperty.call(this.state.selectedCoinBal, "start") ?
                    <Col lg="6" md="6" sm="12" xs="12">
                      <Card style={{ marginBottom: 0, height: this.state.selectedCoinBal.start.msg ? "100%" : "auto" }}>
                        <CardBody >
                          <h4 className="card-title">{this.props.t("Day 1")}</h4>
                          <Chart
                            chartType="PieChart"
                            data={pieDataStart}
                            options={{
                              legend: {
                                textStyle: {
                                  fontSize: 10
                                }
                              },

                              pieSliceText: 'none',
                              pieStartAngle: 180,
                              chartArea: {
                                right: 0,   // set this to adjust the legend width
                                left: 0,     // set this eventually, to adjust the left margin
                                width: 200,
                                height: 270
                              },
                              colors: ["#AED6F1", "#85C1E9", "#5DADE2", "#5DADE2", "#3498DB", "#2E86C1", "#2874A6", "#7FB3D5", "#5499C7", "#2980B9", "#2471A3", "#1F618D", "#154360", "#3A6FA5", "#165290"]
                            }}
                            width={"100%"}
                            height={"350px"}
                          />
                          {
                            !this.state.selectedCoinBal.start.msg ?
                              <div className="text-start">
                                <h4 className="card-title">Market Price</h4>
                                {
                                  bot.trade_coins.map((coins, index) => {
                                    return coins.coin_type != "USDT" ? <span key={index.toString()} style={{ marginRight: 10 }}>

                                      <span key={index.toString()} style={{ marginRight: 5 }}>{coins.coin_type} Price: {this.state.selectedCoinBal.start[coins.coin_type].market.toFixed(3)} USDT</span>
                                      {index > 0 && (index - 1) % 2 == 0 ? (<br />) : (null)}
                                    </span>
                                      : null
                                  })
                                }
                              </div>
                              :
                              null
                          }
                        </CardBody>
                      </Card>
                    </Col>
                    :
                    null
                  }
                  {Object.prototype.hasOwnProperty.call(this.state.selectedCoinBal, "current") ?
                    <Col lg={Object.prototype.hasOwnProperty.call(this.state.selectedCoinBal, "start") ? "6" : "12"} md={Object.prototype.hasOwnProperty.call(this.state.selectedCoinBal, "start") ? "6" : "12"} sm="12" xs="12">
                      <Card style={{ marginBottom: 0 }}>
                        <CardBody >
                          {
                            daysFromStart ?
                              <h4 className="card-title"> {daysFromStart <= 1 ? this.props.t("Same day") : this.props.t("Day") + " " + daysFromStart}</h4>
                              :
                              <h4 className="card-title"> {this.props.t("CURRENT BALLNCE")}</h4>

                          }
                          <Chart
                            chartType="PieChart"
                            data={pieDataEnd}
                            options={{
                              legend: {
                                textStyle: {
                                  fontSize: 10
                                }
                              },

                              pieSliceText: 'none',
                              pieStartAngle: 180,
                              chartArea: {
                                right: 0,   // set this to adjust the legend width
                                left: 0,     // set this eventually, to adjust the left margin
                                width: 200,
                                height: 270
                              },
                              colors: ["#AED6F1", "#85C1E9", "#5DADE2", "#5DADE2", "#3498DB", "#2E86C1", "#2874A6", "#7FB3D5", "#5499C7", "#2980B9", "#2471A3", "#1F618D", "#154360", "#3A6FA5", "#165290"]
                            }}
                            width={"100%"}
                            height={"350px"}
                          />
                          <div className="text-start">
                            <h4 className="card-title">Market Price</h4>
                            {
                              bot.trade_coins.map((coins, index) => {
                                return coins.coin_type != "USDT" ? <span key={index.toString()} style={{ marginRight: 10 }}>

                                  <span key={index.toString()} style={{ marginRight: 5 }}>{coins.coin_type} Price: {this.state.selectedCoinBal.current[coins.coin_type].market.toFixed(3)} USDT</span>
                                  {index > 0 && (index - 1) % (Object.prototype.hasOwnProperty.call(this.state.selectedCoinBal, "start") ? 2 : 5) == 0 ? (<br />) : (null)}
                                </span>
                                  :
                                  null
                              })
                            }

                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    :
                    null
                  }
                </Row>
                :
                null

            }

            {/*
            <Row md="4" sm="6">
            {this.state.reports.map((report, key) => (

              <Col sm="6" xs="12" key={key} className="d-flex flex-column">
                <Card className="flex-grow-1">
                  <CardBody className="d-flex flex-column" style={{ width: '100%' }}>
                    <div className="d-flex">
                      <i className="fa fa-question-circle" />
                      <h5 className="ml-auto flex-grow-1" style={{ color: this.upDownColors(report.desc), textAlign: 'right', whiteSpace: 'pre' }}>{report.desc.replaceAll(' ', '').replaceAll('(', ' (')}</h5>
                    </div>
                    <p className="text-muted mb-4">
                      {report.title}
                    </p>

                    <Row className="mt-auto">
                      <Col xs="12">
                        <p style={{ textAlign: "center", fontSize: 10 }}>{report.value}</p>
                      </Col>

                      <Col xs="12">
                        <div>
                          <div className="apex-charts">
                            <ReactApexChart
                              options={report.options}
                              series={report.series}
                              type="area"
                              height={40}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

            ))}
            </Row>
            */}

            {
              this.props.bots.length ?
                <Row>
                  <WalletBalance />
                  {/* <OverView /> */}
                </Row>
                :
                null
            }

            <Row>
              <Transactions
                openOrders={bot?.open_orders}
                loading={this.state.loadingOrders} />

              {/* <Notifications /> */}
              {/* { <BuySell /> */}
              <Col xs="12">
                <Button className="btn btn-danger btn-sm float-end mb-3" type="button" onClick={() => this.onToggleDeleteBot(bot)} > Delete Bot </Button>
              </Col>
            </Row>

            {/* <Button
            className="btn btn-danger btn-sm float-end"
              type="button"
              style={{ marginLeft: '0.25rem' }}
              onClick={() => this.onToggleDeleteBot(bot)}
            >
              Delete Bot
              </Button> */}
            {this.deleteBotModal()}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


BotDetail.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  bots: PropTypes.array,
  exchangeAPIs: PropTypes.array,
  onGetBots: PropTypes.func,
  onGetAPIs: PropTypes.func,
  onUpdateBot: PropTypes.func,
  onGetOpenOrders: PropTypes.func,
  onDeleteBot: PropTypes.func,
  onGetSelectedCoinBal: PropTypes.func,
}

const mapStateToProps = state => ({
  bots: state.bots.bots,
  exchangeAPIs: state.exchangeAPI.exchangeAPIs,
})

const mapDispatchToProps = dispatch => ({
  onGetBots: (onSuccess, onError) => dispatch(getBots(null, onSuccess, onError)),
  onGetAPIs: () => dispatch(getAPIs()),
  onUpdateBot: bot => dispatch(updateBot(bot)),
  onGetOpenOrders: (id, onSuccess, onError) => dispatch(getOpenOrders(id, onSuccess, onError)),
  onDeleteBot: (bot, onSuccess, onError) => dispatch(deleteBot(bot, onSuccess, onError)),
  onGetSelectedCoinBal: (id, onSuccess, onError) => dispatch(getSelectedCoinBal(id, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BotDetail)))
