import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import Select from "react-select"

import Breadcrumbs from "navigation/Breadcrumb"

import axios from 'axios';

import {
  getAPIs,
  getExchanges,
  getBalance,
  getPrices,
  getCoins,
  getPairs,
  createBot
} from "store/actions"



// --------------------
// MARKET DATA

// const ccxt = require('ccxt');
// const paradise = new ccxt.paradise();
// const binance = new ccxt.binance();
// let markets;
// let prices;

// --------------------
// COLORS

const green = '#5fcb80';
const red = '#f0616e';

// --------------------

import { BOT_DIFFERENCE } from './bot_settings'




const icons = {
  BTC: 'mdi-bitcoin',
  ETH: 'mdi-ethereum',
  XRP: 'mdi-bitcoin',
  USDT: 'mdi-currency-usd',
  BUSD: 'mdi-currency-usd',
  USD: 'mdi-currency-usd',
  JPY: 'mdi-currency-jpy',
}

let paradiseData;

class CreateBot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      isMenu: false,

      name: '',
      selectedExchange: null,
      selectedCoins: [],
      prices: null,
      pairs: [],
      filteredPairs: [],
      selectedPairs: null,
      totalBalance: 9148.23,
      delta: 3,

      assetsUSDT: null,
      assetsAverage: null,

      loadingBalance: false,
      isAPIKeyValid: false,

      loading: false,
      error: null,
      insufficientBalances: null
    }

    this.fetchPrices = this.fetchPrices.bind(this)
    this.fetchPricesSuccess = this.fetchPricesSuccess.bind(this)
    this.fetchPricesError = this.fetchPricesError.bind(this)
    this.onGetBalanceSuccess = this.onGetBalanceSuccess.bind(this)
    this.onGetBalanceError = this.onGetBalanceError.bind(this)

    this.toggleTab = this.toggleTab.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.calculatePairs = this.calculatePairs.bind(this)
    this.calculateAssetsAverage = this.calculateAssetsAverage.bind(this)
    this.coinDifference = this.coinDifference.bind(this)
    this.displayCoinDifference = this.displayCoinDifference.bind(this)
    this.isAssetSufficient = this.isAssetSufficient.bind(this)
    this.calcBalanceErrors = this.calcBalanceErrors.bind(this)
    this.formatBalanceColor = this.formatBalanceColor.bind(this)
    this.formatColumnsWidth = this.formatColumnsWidth.bind(this)
    this.formatCoinList = this.formatCoinList.bind(this)

    this.createBot = this.createBot.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)

    this.redirectToAPIs = this.redirectToAPIs.bind(this)
  }

  componentDidMount() {
    this.props.onGetAPIs()
    this.props.onGetCoins()
    this.props.onGetPairs()
    // this.loadMarkets()
  }

  // async loadMarkets() {
  //   markets = await binance.load_markets();
  //   console.log(markets);
  //   // markets = await fetch('https://api.paradise.exchange/spot/api/v3.2/market_summary', {
  //   //   method: 'GET',
  //   //   mode: 'cors',
  //   //   headers: {
  //   //     'Content-Type': 'application/json'
  //   //   }
  //   // }).then(response => {
  //   //   console.log(response);
  //   // });
  // }

  async fetchPrices(coins) {
    if (!coins || coins.length < 2) return;

    let coinsArray = []
    coins.forEach(coin => {
      if (coin.label && coin.label != 'USDT') {
        coinsArray.push(coin.label + '/USDT')
      }
    });

    this.props.onGetPrices(coinsArray, this.fetchPricesSuccess, this.fetchPricesError)

    // let prices = await paradise.fetchTrades(coinsArray, undefined, 20, {});
    // let prices = await binance.fetchTickers(coinsArray);
    // this.setState({ prices })
  }

  fetchPricesSuccess() {
    this.calculateAssetsAverage()
  }

  fetchPricesError() {
    this.calculateAssetsAverage()
  }





  formatExchanges() {
    const { exchangeAPIs } = this.props;
    if (!exchangeAPIs || !exchangeAPIs.length) return []

    return [
      {
        label: '',
        options: exchangeAPIs.map(api => {
          return { label: api.name + ` (${api.exchange_id?.name})`, value: api._id }
        })
      }
    ]
  }

  formatCoinList() {
    const { coins } = this.props;
    if (!coins || !coins.length) return []

    return [
      {
        label: '',
        options: coins.map(coin => {
          return { label: coin.short_name, value: coin._id }
        })
      }
    ]
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }))
  }

  calculatePairs(selectedCoins) {
    if (!selectedCoins || selectedCoins.length < 2 || !this.props.pairs?.length) return;

    // let existingPairs = {};
    // let coinPairs = [];
    //
    // selectedCoins.forEach((firstCoin, firstIndex) => {
    //   selectedCoins.forEach((secondCoin, secondIndex) => {
    //
    //     if (firstCoin === secondCoin) return;
    //
    //     // Sort pair name alphabetically
    //     let pairLabel = firstCoin.label + '/' + secondCoin.label;
    //     let pairValue = firstCoin.label + '-' + secondCoin.label;
    //     if (secondCoin.value < firstCoin.value) {
    //       pairLabel = secondCoin.label + '/' + firstCoin.label;
    //       pairValue = secondCoin.label + '-' + firstCoin.label;
    //     }
    //
    //     if (existingPairs[pairLabel]) return;
    //     else {
    //       existingPairs[pairLabel] = true;
    //       coinPairs.push({ label: pairLabel, value: pairValue });
    //     }
    //
    //   });
    // });

    // console.log('selectedCoins', selectedCoins);

    const { pairs } = this.props

    let allPossiblePairs = {}
    selectedCoins.forEach(firstCoin => {
      selectedCoins.forEach(secondCoin => {
        allPossiblePairs[`*${firstCoin.label}-${secondCoin.label}*`] = true
        allPossiblePairs[`*${secondCoin.label}-${firstCoin.label}*`] = true
      });
    });

    allPossiblePairs = Object.keys(allPossiblePairs).join(',')

    // console.log('allPossiblePairs', allPossiblePairs);

    let coinPairs = pairs.filter(pair => allPossiblePairs.includes(`*${pair.pair}*`))

    // console.log('coinPairs', coinPairs);

    coinPairs.sort((first, second) => first.pair < second.pair);

    let pairLabels = [{
      label: '',
      options: coinPairs.map(pair => {
        return {
          label: pair.pair.replace('-', '/'),
          value: pair._id
        }
      })
    }]

    this.setState({ pairs: pairLabels })
  }


  calculateAssetsAverage() {
    const { prices } = this.props
    const coins = this.state.selectedCoins?.map(coin => coin.label)
    const { balance } = this.props

    let error = '';
    if (!coins || !coins.length) {
      error += 'No coins. '
    }
    if (!prices) {
      error += 'No prices. '
    }
    if (!balance) {
      error += 'No balance. '
    }

    if (error.trim()) {
      this.setState({ error })
      return;
    }
    else {
      this.setState({ error: null })
    }

    let assetsUSDT = {}
    coins.forEach(coin => {
      // console.log(coin);
      if (coin == 'USD' || coin == 'USDT') {
        assetsUSDT[coin] = balance[coin]
      }
      else {
        // console.log(prices[coin + '/USDT']);
        assetsUSDT[coin] = balance[coin] * prices[coin + '/USDT']?.price
      }
    });

    let values = Object.values(assetsUSDT)
    let total = values.reduce((x, i) => x + i);
    let assetsAverage = total / values.length;

    this.setState({ assetsUSDT, assetsAverage })

    this.calcBalanceErrors()
    // setTimeout(() => {
    // }, 100)
  }

  coinDifference(coin) {
    const { assetsUSDT, assetsAverage } = this.state

    let difference = assetsUSDT?.[coin] / assetsAverage;
    return difference - 1;
  }

  displayCoinDifference(coin) {
    let difference = this.coinDifference(coin) * 100;
    if (isNaN(difference)) return ' (-- %)'
    let plus = difference > 0 ? '+' : '';
    return ` (${plus}${difference.toFixed(1)}%)`
  }

  isAssetSufficient(coin) {
    let difference = this.coinDifference(coin)

    // e.g. if difference is 0.3 (30%), is the balance
    // between 0.7 (70%) and 1.3 (130%) of average
    return Math.abs(difference) <= BOT_DIFFERENCE;
  }

  calcBalanceErrors() {
    const { assetsUSDT } = this.state

    let errors = [];
    Object.keys(assetsUSDT).forEach(coin => {
      if (!this.isAssetSufficient(coin)) {
        errors.push(`${coin} balance is more than 30% beyond the average.`);
      }
    });

    if (!errors.length) errors = "";

    this.setState({ insufficientBalances: errors })
  }

  formatBalanceColor(coin) {
    return
  }

  formatColumnsWidth() {
    const { selectedCoins } = this.state;
    if (!selectedCoins || selectedCoins.length <= 1) return '12';
    else if (selectedCoins.length === 2) return '6';
    else return '4';
  }

  handleExchangeSelect = selectedExchange => {
    this.setState({
      selectedExchange,
      loadingBalance: true
    })
    this.props.onGetBalance(selectedExchange.value, this.onGetBalanceSuccess, this.onGetBalanceError)
  }
  handleCoinSelect = async (selectedCoins, context) => {
    this.setState({ insufficientBalances: null })
    if (this.state.selectedCoins && this.state.selectedCoins.length >= 10) {
      if (context.action === 'remove-value') {
        let localselectedCoins = this.state.selectedCoins.filter(function (obj) {
          return obj.label !== context.removedValue.label;
        });
        this.setState({
          // error: this.props.t("You can not choose more than 10 coins"),
          selectedCoins: localselectedCoins
        })
      }
      else if (context.action === 'clear') {
        this.setState({
          // error: this.props.t("You can not choose more than 10 coins"),
          selectedCoins: [],
          selectedPairs: null,
        })
      }
      else {
        // this.setState({
        //   error: this.props.t("You can not choose more than 10 coins")
        // })
      }
    }
    else {
      await this.fetchPrices(selectedCoins)
      this.calculatePairs(selectedCoins)
      this.setState({
        selectedCoins: selectedCoins ? selectedCoins : [],
        selectedPairs: null,
        // error: ''
      })
    }
  }
  handlePairSelect = selectedPairs => {
    this.setState({ selectedPairs })
  }

  onGetBalanceSuccess(response) {
    this.setState({
      loadingBalance: false,
      isAPIKeyValid: true
    })
  }

  onGetBalanceError(response) {
    this.setState({
      error: response.data.msg,
      loadingBalance: false,
      isAPIKeyValid: false
    })
  }

  createBot() {
    const {
      name,
      delta,
      selectedExchange,
      selectedCoins,
      selectedPairs,
    } = this.state
    const { balance } = this.props


    if (!name.trim()) {
      this.setState({ error: 'Please enter a name.' })
      return;
    }
    if (!delta) {
      this.setState({ error: 'Please enter a delta amount (% per trade).' })
      return;
    }
    if (delta % 1 != 0 || delta < 1 || delta > 10) {
      this.setState({ error: 'Please enter a delta amount (% per trade) between 1 to 10. Decimal are not allowed' })
      return;
    }
    if (!selectedExchange) {
      this.setState({ error: 'Please select an exchange.' })
      return;
    }
    if (!selectedCoins || selectedCoins.length < 2) {
      this.setState({ error: 'Please select two or more coins.' })
      return;
    }
    if (!selectedPairs || !selectedPairs.length) {
      this.setState({ error: 'Please select one or more pairs.' })
      return;
    }


    this.setState({ loading: true });

    let coin_details = {};
    selectedCoins.forEach(coin => {
      coin_details[coin.label] = balance[coin.label]
    })

    let newBot = {
      name: name,
      api_id: this.state.selectedExchange.value,
      coins: selectedCoins.map(coin => coin.value),
      pairs: selectedPairs.map(pair => pair.label?.replace('/', '-')),
      delta: delta / 100,
      coin_details
    }

    this.props.onCreateBot(newBot, this.onSuccess, this.onError);
  }

  onSuccess() {
    this.setState({ loading: false })
    this.props.history.push('/bot-list')
  }

  onError(response) {
    this.setState({
      loading: false,
      error: response.msg
    })
  }



  redirectToAPIs() {
    this.props.history.push('/new-api')
  }

  render() {
    const {
      selectedPairs,
      selectedCoins,
      exchangesDisplay,
      insufficientBalances,
      assetsUSDT,
      assetsAverage
    } = this.state;
    const { balance } = this.props;

    // let firstCoin, secondCoin;
    // if (selectedPairs) {
    //   [firstCoin, secondCoin] = selectedPairs.label.split('/');
    // }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Talmud | New Bot</title>
          </MetaTags>
          <Container fluid>

            <Breadcrumbs
              title="My Bots"
              breadcrumbItem="Create New Bot"
              parent="/bot-list"
            />

            <Card className="mx-auto" style={{ maxWidth: '720px' }}>
              <CardBody>
                {this.state.selectedExchange && this.state.isAPIKeyValid ? (
                  <div className="float-end">
                    <Dropdown
                      isOpen={this.state.isMenu}
                      toggle={this.toggleMenu}
                    >
                      <DropdownToggle
                        type="button"
                        tag="button"
                        className="btn btn-light"
                      >
                        <i className="mdi mdi-wallet me-1" />
                        <span className="d-none d-sm-inline-block ms-1">
                          Wallet Balance{" "}
                          <i className="mdi mdi-chevron-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end dropdown-menu-md"
                        style={{ maxHeight: '600px', overflowY: 'auto' }}
                      >
                        <div className="dropdown-item-text">
                          <div>
                            <p className="text-muted mb-2">
                              Available Balance
                            </p>
                          </div>
                        </div>

                        <DropdownItem divider />

                        {balance && Object.keys(balance).map(coin => {
                          return balance[coin] ? (
                            <DropdownItem href="#" key={coin}>
                              {coin}<span className="float-end">{balance[coin]}</span>
                            </DropdownItem>
                          ) : ''
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                ) : ''}

                <h4 className="card-title mb-4">New Bot</h4>

                <div className="crypto-buy-sell-nav">
                  <Form>

                    {this.props.exchangeAPIs?.length ? (
                      <div className="mb-3">
                        <Label>Exchange</Label>
                        <Select
                          value={this.state.selectedExchange}
                          onChange={this.handleExchangeSelect}
                          options={this.formatExchanges()}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    ) : (
                      <div className="mb-3">
                        <p className="text-muted">You have not added any API keys.</p>
                      </div>
                    )}

                    {this.state.loadingBalance && (
                      <div className="p-4">
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                        <span>Loading balance...</span>
                      </div>
                    )}

                    {this.state.selectedExchange && this.state.isAPIKeyValid ? (
                      <React.Fragment>
                        <div className="mb-3">
                          <Label>Bot Name</Label>
                          <Input type="text" className="form-control"
                            maxLength={30}
                            onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>

                        <div className="mb-3">
                          <Label>Delta per Trade (%)</Label>
                          <Input type="number" className="form-control"
                            // defaultValue={3}
                            min={0}
                            max={100}
                            value={this.state.delta}
                            onChange={(e) => this.setState({ delta: parseFloat(e.target.value) })}
                            onWheel={(e) => e.target.blur()} />
                        </div>

                        <div className="mb-3 select2-container">
                          <label className="control-label">
                            Select Coins
                          </label>
                          <Select
                            value={this.state.selectedCoins}
                            isMulti={true}
                            onChange={this.handleCoinSelect}
                            options={this.formatCoinList()}
                            classNamePrefix="select2-selection"
                          />
                          <label className="text-muted">You can choose maximum 10 coins</label>
                        </div>

                        {(selectedCoins?.length >= 2) && (
                          <React.Fragment>
                            <Label>Balance</Label>
                            <Row>
                              <p><span className="text-primary">Assets Average:</span> {this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + ' USDT'}</p>
                            </Row>

                            <Row className="mb-3">
                              {selectedCoins.map(coin => {
                                return (
                                  <Col sm={this.formatColumnsWidth()} key={coin.value}>
                                    <Label className="card-radio-label mb-2">
                                      <div className="card-radio">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <i className={`mdi ${icons[coin.label]} font-size-24 text-warning align-middle me-2`} />{" "}
                                          <span>{coin.label}</span>
                                          <span style={{
                                            marginLeft: 'auto',
                                            fontWeight: '400',
                                            color: this.isAssetSufficient(coin.label) ? green : red
                                          }}>{balance[coin.label] + this.displayCoinDifference(coin.label)}</span>
                                        </div>
                                      </div>
                                    </Label>
                                  </Col>
                                )
                              })}
                            </Row>
                          </React.Fragment>
                        )}

                        {insufficientBalances && insufficientBalances.map((error, index) => {
                          return (
                            <Alert color="danger" role="alert" key={index}>
                              {error}
                            </Alert>
                          )
                        })}

                        {insufficientBalances && (
                          <Alert color="danger" role="alert">
                            Please adjust your portfolio balances and return to this page.
                          </Alert>
                        )}

                        <div className="mb-3 select2-container">
                          <label className="control-label">
                            Select Pair
                          </label>
                          <Select
                            value={selectedPairs}
                            isMulti={true}
                            onChange={this.handlePairSelect}
                            options={this.state.pairs}
                            classNamePrefix="select2-selection"
                          />
                        </div>

                        {/*
                        {(selectedCoins && selectedCoins.length) && (
                          <React.Fragment>

                            <Label>Current Balance</Label>

                            <Row className="mb-3">
                              <Col sm="6">
                                <Label className="card-radio-label mb-2">
                                  <div className="card-radio">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <i className={`mdi ${icons[firstCoin]} font-size-24 text-warning align-middle me-2`} />{" "}
                                      <span>{firstCoin}</span>
                                      <span className="text-muted" style={{
                                        marginLeft: 'auto',
                                        fontWeight: '400'
                                      }}>{balance[firstCoin]}</span>
                                    </div>
                                  </div>
                                </Label>
                              </Col>

                              <Col sm="6">
                                <Label className="card-radio-label mb-2">
                                  <div className="card-radio">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <i className={`mdi ${icons[secondCoin]} font-size-24 text-primary align-middle me-2`} />{" "}
                                      <span>{secondCoin}</span>
                                      <span className="text-muted" style={{
                                        marginLeft: 'auto',
                                        fontWeight: '400'
                                      }}>{balance[secondCoin]}</span>
                                    </div>
                                  </div>
                                </Label>
                              </Col>
                            </Row>

                          </React.Fragment>
                        )}
                        */}
                      </React.Fragment>
                    ) : ''}


                    {this.state.error && (
                      <Alert color="danger" role="alert">
                        {this.state.error}
                      </Alert>
                    )}

                    {this.props.exchangeAPIs?.length ? (
                      <div className="text-center mt-4">
                        <Alert color="warning" role="alert">
                          <span className="fw-bold">Warning:</span> Bots cannot be edited while the bot is running.
                        </Alert>
                        <Button type="button" color="success"
                          onClick={this.createBot}
                          disabled={
                            !this.state.selectedExchange ||
                              !this.state.name.trim() ||
                              !this.state.delta ||
                              !this.state.selectedCoins.length ||
                              !this.state.selectedPairs ||
                              this.state.insufficientBalances != "" ? true : false
                          }
                        >
                          {this.state.loading ? (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                          ) : 'Create Bot'}
                        </Button>
                      </div>
                    ) : (
                      <div className="text-center mt-4">
                        <Button type="button" color="success"
                          onClick={this.redirectToAPIs}
                        >
                          Add Exchange API Key
                        </Button>
                      </div>
                    )}

                  </Form>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CreateBot.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  exchangeAPIs: PropTypes.array,
  balance: PropTypes.object,
  prices: PropTypes.object,
  coins: PropTypes.array,
  pairs: PropTypes.array,
  exchanges: PropTypes.array,
  onGetAPIs: PropTypes.func,
  onGetBalance: PropTypes.func,
  onGetPrices: PropTypes.func,
  onGetCoins: PropTypes.func,
  onGetPairs: PropTypes.func,
  onCreateBot: PropTypes.func,
}

const mapStateToProps = state => ({
  exchangeAPIs: state.exchangeAPI.exchangeAPIs,
  balance: state.user.balance,
  prices: state.user.prices,
  coins: state.bots.coins,
  pairs: state.bots.pairs,
})

const mapDispatchToProps = dispatch => ({
  onGetAPIs: (onFetch) => dispatch(getAPIs(onFetch)),
  onGetBalance: (botID, onSuccess, onError) => dispatch(getBalance(botID, onSuccess, onError)),
  onGetPrices: (coins, onSuccess, onError) => dispatch(getPrices(coins, onSuccess, onError)),
  onGetCoins: (onFetch) => dispatch(getCoins(onFetch)),
  onGetPairs: () => dispatch(getPairs()),
  onCreateBot: (bot, onSuccess, onError) => dispatch(createBot(bot, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CreateBot)))
