
export const GET_BOTS = "GET_BOTS"
export const GET_BOTS_SUCCESS = "GET_BOTS_SUCCESS"
export const GET_BOTS_FAIL = "GET_BOTS_FAIL"

export const GET_COINS = "GET_COINS"
export const GET_COINS_SUCCESS = "GET_COINS_SUCCESS"
export const GET_COINS_FAIL = "GET_COINS_FAIL"

export const GET_PAIRS = "GET_PAIRS"
export const GET_PAIRS_SUCCESS = "GET_PAIRS_SUCCESS"
export const GET_PAIRS_FAIL = "GET_PAIRS_FAIL"

export const CREATE_BOT = "CREATE_BOT"
export const CREATE_BOT_SUCCESS = "CREATE_BOT_SUCCESS"
export const CREATE_BOT_FAIL = "CREATE_BOT_FAIL"

export const EDIT_BOT = "EDIT_BOT"
export const EDIT_BOT_SUCCESS = "EDIT_BOT_SUCCESS"
export const EDIT_BOT_FAIL = "EDIT_BOT_FAIL"

export const START_STOP_BOT = "START_STOP_BOT"
export const START_STOP_BOT_SUCCESS = "START_STOP_BOT_SUCCESS"
export const START_STOP_BOT_FAIL = "START_STOP_BOT_FAIL"

export const UPDATE_BOT = "UPDATE_BOT"
export const UPDATE_BOT_SUCCESS = "UPDATE_BOT_SUCCESS"
export const UPDATE_BOT_FAIL = "UPDATE_BOT_FAIL"

export const DELETE_BOT = "DELETE_BOT"
export const DELETE_BOT_SUCCESS = "DELETE_BOT_SUCCESS"
export const DELETE_BOT_FAIL = "DELETE_BOT_FAIL"

export const GET_OPEN_ORDERS = "GET_OPEN_ORDERS"
export const GET_OPEN_ORDERS_SUCCESS = "GET_OPEN_ORDERS_SUCCESS"
export const GET_OPEN_ORDERS_FAIL = "GET_OPEN_ORDERS_FAIL"

export const GET_SELECTED_COIN_BAL = "GET_SELECTED_COIN_BAL"
export const GET_SELECTED_COIN_BAL_SUCCESS = "GET_SELECTED_COIN_BAL_SUCCESS"
export const GET_SELECTED_COIN_BAL_FAIL = "GET_SELECTED_COIN_BAL_FAIL"