import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_EXCHANGES,
  GET_APIS,
  CREATE_API,
  UPDATE_API,
  DELETE_API,
} from "./actionTypes"
import {
  getExchangesSuccess,
  getExchangesFail,
  getAPIsSuccess,
  getAPIsFail,
  createAPISuccess,
  createAPIFail,
  updateAPISuccess,
  updateAPIFail,
  deleteAPISuccess,
  deleteAPIFail,
} from "./actions"

import { get, post } from "helpers/api_helper"


function* fetchExchanges({ onFetch }) {
  try {
    const response = yield call(post, 'exchanges/all')
    yield put(getExchangesSuccess(response.data))
    if (onFetch) onFetch()
  } catch (error) {
    yield put(getExchangesFail(error))
  }
}


function* fetchAPIs({ onFetch }) {
  try {
    const response = yield call(post, 'exchanges/apiList')
    yield put(getAPIsSuccess(response.data))
    if (onFetch) onFetch()
  } catch (error) {
    yield put(getAPIsFail(error))
  }
}


function* onCreateAPI({ payload: api, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/createApi', api)
    if (response.status == 'success') {
      onSuccess(response)
      yield put(createAPISuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    onError(error)
    yield put(updateAPIFail(error))
  }
}


function* onUpdateAPI({ payload: api }) {
  try {
    const response = yield call(post, 'exchanges/updateApi', api)
    yield put(updateAPISuccess(response.data))
  } catch (error) {
    yield put(updateAPIFail(error))
  }
}


function* onDeleteAPI({ payload: api, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/deleteApi', { id: api._id })
    onSuccess({ ...response, api })
    yield put(deleteAPISuccess(api))
  } catch (error) {
    onError(error.response)
    yield put(deleteAPIFail(error))
  }
}


function* exchangeAPISaga() {
  yield takeEvery(GET_EXCHANGES, fetchExchanges)
  yield takeEvery(GET_APIS, fetchAPIs)
  yield takeEvery(CREATE_API, onCreateAPI)
  yield takeEvery(UPDATE_API, onUpdateAPI)
  yield takeEvery(DELETE_API, onDeleteAPI)
}

export default exchangeAPISaga
