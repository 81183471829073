import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Modal,
} from "reactstrap"
import classnames from "classnames"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import QRious from 'qrious';

import { wallet, orders } from './stubWallet'
import {
  getBalance,
  verifyPassword,
  verify2fa,
  withdraw,
  getMe
} from "store/actions"


import WalletValidator from 'wallet-address-validator'


class Transfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,
      copySuccess: null,
      copyError: null,

      activeTab: 'withdraw',
      dropdownVisible: false,
      confirmTransferModalVisible: false,
      passwordModalVisible: false,
      password: '',

      verify2faVisible: false,
      code2fa: null,
      error2fa: null,

      amount: 0,
      isAmountValid: false,
      recipientWalletAddress: '',
      myWalletAddress: '',
      transferComplete: false,
      minSVC: 200,
    }

    this.onClickCopy = this.onClickCopy.bind(this)

    this.toggleTab = this.toggleTab.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)

    this.toggleConfirmTransferModal = this.toggleConfirmTransferModal.bind(this)
    this.togglePasswordModal = this.togglePasswordModal.bind(this)
    this.toggle2faModal = this.toggle2faModal.bind(this)

    this.onAmountChange = this.onAmountChange.bind(this)
    this.onFirstTransferClick = this.onFirstTransferClick.bind(this)
    this.transferClicked = this.transferClicked.bind(this)
    this.verifyPassword = this.verifyPassword.bind(this)
    this.onVerifyPasswordSuccess = this.onVerifyPasswordSuccess.bind(this)
    this.onVerifyPasswordError = this.onVerifyPasswordError.bind(this)
    this.verify2FA = this.verify2FA.bind(this)
    this.onVerify2FAError = this.onVerify2FAError.bind(this)
    this.transfer = this.transfer.bind(this)
    this.transferSuccess = this.transferSuccess.bind(this)
    this.transferError = this.transferError.bind(this)
  }

  componentDidMount() {
    let type = this.props.match.params.type
    this.setState({ activeTab: type })
  }

  componentDidUpdate() {
    let qr = new QRious({
      element: document.getElementById('qr-code-deposit'),
      value: this.props.user?.address?.address || 'No address found'
    })
  }


  onAmountChange(e) {
    let value = e.target.value;
    let decimals = value?.toString().split('.')[1];
    if (decimals?.length > 18) {
      this.setState({
        isAmountValid: false,
        error: 'Max decimal places for SVC is 18.'
      })
    }
    else {
      this.setState({
        isAmountValid: true,
        amount: e.target.value
      })
    }
  }

  onFirstTransferClick() {
    if (this.state.amount <= 0) {
      this.setState({ error: 'Amount must be more than 0.' })
      return;
    }

    const has2fa = this.props.user.two_fa_enable
    if (!has2fa) {
      this.setState({ error: 'Please enable 2FA for withdraw.' })
      setTimeout(() => {
        window.location.href = '/security'
      }, 1500)
      return;
    }

    let isAddressValid = WalletValidator.validate(this.state.recipientWalletAddress, 'ETH')
    if (isAddressValid) {
      this.setState({ confirmTransferModalVisible: true })
    }
    else {
      this.setState({ error: 'Invalid ETH wallet address.' })
    }
  }

  transferClicked() {
    this.setState({
      confirmTransferModalVisible: false,
      passwordModalVisible: true
    })
  }

  verifyPassword() {
    this.setState({ loading: true })
    let params = { password: this.state.password }
    this.props.onVerifyPassword(params, this.onVerifyPasswordSuccess, this.onVerifyPasswordError)
    // this.onVerifyPasswordSuccess(null)
  }

  onVerifyPasswordSuccess(response) {
    let has2fa = this.props.user?.two_fa_enable
    if (has2fa) {
      this.setState({
        loading: false,
        passwordModalVisible: false,
        verify2faVisible: true,
        error: null,
        error2fa: null,
      })
    }
    else this.transfer()
  }

  onVerifyPasswordError(response) {
    this.setState({
      loading: false,
      error2fa: response?.data?.msg
    });
  }

  verify2FA() {
    this.props.onVerify2FA(this.state.code2fa, this.transfer, this.onVerify2FAError)
  }

  onVerify2FAError(response) {
    this.setState({
      loading: false,
      error2fa: response?.data?.msg
    });
  }

  transfer() {
    this.setState({
      confirmTransferModalVisible: false,
      passwordModalVisible: false,
      verify2faVisible: false,
      loading: true
    })

    // setTimeout(this.transferSuccess, 1500)
    let params = {
      to_address: this.state.recipientWalletAddress,
      amount: this.state.amount,
      platform: 'MATIC',
      password: this.state.password
    }

    if (this.props.user?.two_fa_enable) {
      params.token_code = this.state.code2fa;
    }

    this.props.onWithdraw(params, this.transferSuccess, this.transferError)
  }

  transferSuccess(response) {
    this.setState({
      loading: false,
      transferComplete: true,
      success: 'Transfer Successful! An email will be sent to your email address. Click the link in the email to approve this transfer.',
      error: null
    })
    this.props.onGetMe()
  }

  transferError(response) {
    this.setState({
      loading: false,
      error: response?.data?.msg
    })
  }


  onClickCopy() {
    let input = document.getElementById('deposit-address');
    if (!input) {
      this.setState({ copyError: 'Problem copying to clipboard. Please copy manually.' })
      return;
    }
    input.focus();
    input.select();
    document.execCommand('copy', false);
    input.blur();
    window.getSelection().removeAllRanges();

    this.setState({ copySuccess: 'Copied to clipboard.' })
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      dropdownVisible: !prevState.dropdownVisible,
    }))
  }

  toggleConfirmTransferModal() {
    this.setState({ confirmTransferModalVisible: !this.state.confirmTransferModalVisible })
  }
  togglePasswordModal() {
    this.setState({ passwordModalVisible: !this.state.passwordModalVisible })
  }
  toggle2faModal() {
    this.setState({ verify2faVisible: !this.state.verify2faVisible })
  }

  confirmTransferModal() {
    return (
      <Modal
        isOpen={this.state.confirmTransferModalVisible}
        toggle={this.toggleConfirmTransferModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Withdraw Funds</h5>
          <button
            type="button"
            onClick={this.toggleConfirmTransferModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <p>Send <span className="text-primary fw-bold">{this.state.amount}</span> SVC to wallet <span className="text-primary fw-bold">{this.state.recipientWalletAddress}</span>?</p>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleConfirmTransferModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.transferClicked}
          >
            Confirm
            </button>
        </div>
      </Modal>
    )
  }

  passwordModal() {
    return (
      <Modal
        isOpen={this.state.passwordModalVisible}
        toggle={this.togglePasswordModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Password</h5>
          <button
            type="button"
            onClick={this.togglePasswordModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">

          <p>Confirm password</p>

          <Input
            type="password"
            className="form-control"
            onInput={(e) => this.setState({ password: e.target.value })}
          />

          {this.state.error2fa && (
            <Alert color="danger" role="alert" className="mt-3">
              {this.state.error2fa}
            </Alert>
          )}

        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.togglePasswordModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.verifyPassword}
            disabled={!this.state.password?.trim()}
          >
            {this.state.loading ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
            ) : 'Submit'}
            </button>
        </div>
      </Modal>
    )
  }

  twoFAModal() {
    return (
      <Modal
        isOpen={this.state.verify2faVisible}
        toggle={this.toggle2faModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Two Factor Authentication</h5>
          <button
            type="button"
            onClick={this.toggle2faModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">

          <p>Please enter your Google Authenticator code below.</p>

          <Input
            type="number"
            className="form-control no-spinner"
            onInput={(e) => this.setState({ code2fa: e.target.value })}
          />

          {this.state.error2fa && (
            <Alert color="danger" role="alert" className="mt-3">
              {this.state.error2fa}
            </Alert>
          )}

        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggle2faModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.verify2FA}
            disabled={!this.state.code2fa}
          >
          {this.state.loading ? (
            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
          ) : 'Submit'}
          </button>
        </div>
      </Modal>
    )
  }

  render() {
    let balance = this.props.user?.address?.confirmed_balance
    let isAmountSufficient = this.state.amount && (this.state.amount - this.state.minSVC) > 0

    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Talmud | Transfer Funds</title>
          </MetaTags>
          <Container fluid>

            <Breadcrumbs
              title="Wallet"
              breadcrumbItem="Transfer"
              parent="/wallet"
            />

            {this.confirmTransferModal()}
            {this.passwordModal()}
            {this.twoFAModal()}

            <Row>
              <Col lg="12">
                <Card style={{ maxWidth: '720px', margin: 'auto' }}>
                  <CardBody>
                    <div className="float-end">
                    <Dropdown
                      isOpen={this.state.dropdownVisible}
                      toggle={this.toggleMenu}
                    >
                      <DropdownToggle
                        type="button"
                        tag="button"
                        className="btn btn-light"
                      >
                        <i className="mdi mdi-wallet me-1" />
                        <span className="d-none d-sm-inline-block ms-1">
                          Wallet Balance{" "}
                          <i className="mdi mdi-chevron-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end dropdown-menu-md"
                        style={{ maxHeight: '600px', overflowY: 'auto' }}
                      >
                        <div className="dropdown-item-text">
                          <div>
                            <p className="text-muted mb-2">
                              Available Balance
                            </p>
                          </div>
                        </div>

                        <DropdownItem divider />

                        {/* wallet && Object.keys(wallet.balance).map(coin => {
                          return (
                            <DropdownItem href="#" key={coin}>
                              {coin}<span className="float-end">{wallet.balance[coin]}</span>
                            </DropdownItem>
                          )
                        }) */}
                        <DropdownItem href="#">
                          SVC<span className="float-end">{balance}</span>
                        </DropdownItem>

                      </DropdownMenu>
                    </Dropdown>
                    </div>
                    <h4 className="card-title mb-4">Transfer Assets</h4>


                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab == 'withdraw',
                            })}
                            onClick={() => {
                              this.toggleTab('withdraw')
                            }}
                          >
                            Withdraw
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab == 'deposit',
                            })}
                            onClick={() => {
                              this.toggleTab('deposit')
                            }}
                          >
                            Deposit
                          </NavLink>
                        </NavItem>
                      </Nav>



                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="withdraw">
                          <p className="text-muted mb-0">Send crypto assets to another wallet.</p>
                          <p className="text-muted mb-4">{"Withdraw Fee: "+this.state.minSVC+" SVC"}</p>

                          {!this.state.transferComplete && (
                            <div className="mb-3">
                              <Label>Recipient Wallet Address</Label>
                              <Input
                                type="text"
                                className="form-control"
                                onInput={(e) => this.setState({ recipientWalletAddress: e.target.value })}
                              />
                            </div>
                          )}

                          {!this.state.transferComplete && (
                            <div className="mb-3">
                              <Label>Amount</Label>
                              <InputGroup className="mb-2">
                                <Input
                                  type="number"
                                  className="form-control"
                                  onInput={this.onAmountChange}
                                />

                                <span className="input-group-text">
                                  SVC
                                  </span>
                              </InputGroup>
                              {this.state.amount ? (
                                <React.Fragment>
                                  <div className="d-flex">
                                    <p className="text-muted mb-0">Withdraw Fee</p>
                                    <p className="text-muted mb-0 ms-auto">{"-"+this.state.minSVC+" SVC"}</p>
                                  </div>
                                  <div className="d-flex">
                                    <p className={`mb-0 ${isAmountSufficient ? 'text-success' : 'text-danger'}`}>Total</p>
                                    <p className={`mb-0 ms-auto ${isAmountSufficient ? 'text-success' : 'text-danger'}`}>{this.state.amount - this.state.minSVC} SVC</p>
                                  </div>
                                </React.Fragment>
                              ) : ''}
                            </div>
                          )}

                          {this.state.success && (
                            <Alert color="success" role="alert">
                              {this.state.success}
                            </Alert>
                          )}

                          {this.state.error && (
                            <Alert color="danger" role="alert">
                              {this.state.error}
                            </Alert>
                          )}

                          {!this.state.transferComplete && (
                            <div className="text-center mt-4">
                              <Button type="button" color="success"
                                onClick={this.onFirstTransferClick}
                                disabled={
                                  !this.state.amount ||
                                  !this.state.recipientWalletAddress.trim() ||
                                  !this.state.isAmountValid ||
                                  !isAmountSufficient
                                }
                              >
                                {this.state.loading ? (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                                ) : 'Transfer'}
                              </Button>
                            </div>
                          )}
                        </TabPane>



                        <TabPane tabId="deposit">
                          <p className="text-muted mb-4">Send your crypto assets from another Polygon (MATIC) wallet to the wallet address below.</p>

                          <canvas id="qr-code-deposit" className="d-block mb-4 mx-auto"></canvas>

                          <Label>My Wallet Address</Label>

                          <div className="input-group mb-3">
                            <Input
                              id="deposit-address"
                              type="text"
                              className="form-control"
                              readOnly
                              value={this.props.user?.address?.address || 'No address found'}
                            />
                            <button type="button" className="btn btn-primary"
                              onClick={this.onClickCopy}
                              disabled={!this.props.user?.address?.address}
                            >Copy</button>
                          </div>

                          {this.state.copySuccess && (
                            <Alert color="success" role="alert">
                              {this.state.copySuccess}
                            </Alert>
                          )}

                          {this.state.copyError && (
                            <Alert color="danger" role="alert">
                              {this.state.copyError}
                            </Alert>
                          )}

                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Transfer.propTypes = {
  match: PropTypes.any,
  user: PropTypes.any,
  // onGetWallet: PropTypes.func,
  onVerifyPassword: PropTypes.func,
  onVerify2FA: PropTypes.func,
  onWithdraw: PropTypes.func,
  onGetMe: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = dispatch => ({
  // onGetWallet: () => dispatch(getWallet()),
  onVerifyPassword: (password, onSuccess, onError) => dispatch(verifyPassword(password, onSuccess, onError)),
  onVerify2FA: (code, onSuccess, onError) => dispatch(verify2fa(code, onSuccess, onError)),
  onWithdraw: (params, onSuccess, onError) => dispatch(withdraw(params, onSuccess, onError)),
  onGetMe: (onSuccess, onError) => dispatch(getMe(onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Transfer))
