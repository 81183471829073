import React, { Component } from "react"
import PropTypes from 'prop-types'

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { AvField, AvForm } from "availity-reactstrap-validation"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"

import { verify } from "store/actions"

import * as CryptoJS from 'crypto-js'
import secret from 'secretConfig'

import Footer from './../../navigation/Footer';


class Verify extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,
      verified: true
    }

    this.onVerificationSuccess = this.onVerificationSuccess.bind(this)
    this.onVerificationError = this.onVerificationError.bind(this)
  }

  componentDidMount() {
    let code = this.props.match.params.id;
    this.props.onVerify(code, this.onVerificationSuccess, this.onVerificationError)
  }

  onVerificationSuccess(response) {
    this.setState({
      verified: true,
      success: 'Email verified!'
    })
  }

  onVerificationError(response) {
    this.setState({
      verified: false,
      error: response.data?.msg
    })

  }


  render() {
    return (
      // <React.Fragment>
      <div>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div> */}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">

                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Verifying your email...</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0">

                    {/*
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    */}

                    <div className="p-3 pt-5">
                      {this.state.success && (
                        <Alert color="success" role="alert">
                          {this.state.success}
                        </Alert>
                      )}

                      {this.state.error && (
                        <Alert color="danger" role="alert">
                          {this.state.error}
                        </Alert>
                      )}

                      {this.state.verified && (
                        <Link to="/login">Go to Login</Link>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Footer auth={true} />
          </Container>
        </div>
      </div>
      // </React.Fragment>
    )
  }
}

Verify.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  error: PropTypes.any,
  onVerify: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onVerify: (code, onSuccess, onError) => dispatch(verify(code, onSuccess, onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Verify)))
