import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import PropTypes from "prop-types";

import ReactApexChart from "react-apexcharts"

class MiniWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.upDownColors = this.upDownColors.bind(this)
  }

  upDownColors(text) {
    let value = text.split('(')[0];
    value = value.replace('+', '');
    value = value.replace('- ', '-');
    value = parseFloat(value);

    if (value > 0) return '#20c671';
    else if (value < 0) return '#eb3737';
    else return '#000';
  }

  render() {
    return (
      <React.Fragment>
        {this.props.reports.map((report, key) => (
          <Col sm="6" xs="12" key={key} className="d-flex flex-column">
            <Card className="flex-grow-1">
              <CardBody className="d-flex flex-column" style={{ width: '100%' }}>
                <div className="d-flex">
                  <i className="fa fa-question-circle" />
                  <h5 className="ml-auto flex-grow-1" style={{ color: this.upDownColors(report.desc), textAlign: 'right', whiteSpace: 'pre' }}>{report.desc.replaceAll(' ', '').replaceAll('(', ' (')}</h5>
                </div>
                <p className="text-muted mb-4">
                  {/* <i className={`${report.icon} h2 text-${report.color} align-middle mb-0 me-3`}/> */}
                  {report.title}
                </p>

                <Row className="mt-auto">
                  {/* <Col sm="9" xs="3">
                    <div style={{ alignItems: "center" }}>
                      <p style={{
                        fontWeight:"bold",
                        alignSelf:"center",
                        fontSize:10
                      }}>{report.title}</p>
                    </div>
                  </Col> */}
                  {/* <Col sm="12" xs="6">
                    <div style={{
                      flexDirection:"row",
                      display:"flex",
                      alignItems:"flex-end"
                    }}>
                      <p style={{
                        color:"gray",
                        alignSelf:"flex-end",
                        textAlign:"inherit",
                        fontSize:10
                      }}>{"the day before yesterday"}</p>
                      <i className="ms-1 mdi mdi-arrow-right" style={{alignSelf:"center", color:"gray"}}/>
                      <p className="text-muted text-truncate mb-0">
                        {report.desc}{" "}
                        <i className={"ms-1 mdi " + report.arrow}/>
                      </p>
                    </div>
                  </Col> */}
                  <Col xs="12">
                    <p style={{ textAlign: "center", fontSize: 10 }}>{report.value}</p>
                  </Col>

                  <Col xs="12">
                    <div>
                      <div className="apex-charts">
                        <ReactApexChart
                          options={report.options}
                          series={report.series}
                          type="area"
                          height={40}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    )
  }
}

MiniWidget.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape([PropTypes.object])
  )
}

export default MiniWidget
