import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import ReactApexChart from "react-apexcharts"

import {
  getBots,
  getBalance,
  getPrices,
} from "store/actions"


const green = '#5fcb80';
const red = '#f0616e';

const colors = ["#3452e1", "#f1b44c", "#bb3838", "#495057"]
const color = (index) => {
  if (isNaN(index)) return null;
  return colors[index % colors.length]
}


class WalletBalance extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      startingAssets: null,
      startingAssetsSum: null,
      currentAssets: null,
      currentAssetsSum: null,
      profitPercentages: null,


      series: [76, 67, 61],
      labels: [""]
    }

    this.onError = this.onError.bind(this)
    this.botsSuccess = this.botsSuccess.bind(this)
    this.pricesSuccess = this.pricesSuccess.bind(this)
    this.balanceSuccess = this.balanceSuccess.bind(this)

    this.walletOptions = this.walletOptions.bind(this)
    this.totalProfitPercent = this.totalProfitPercent.bind(this)
  }

  walletOptions() {
    const { totalProfitPercent } = this.state
    const { labels } = this.state
    return {
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "35%",
            background: "transparent",
            image: void 0,
          },
          track: {
            show: !0,
            startAngle: void 0,
            endAngle: void 0,
            background: "#f2f2f2",
            strokeWidth: "97%",
            opacity: 1,
            margin: 12,
            dropShadow: {
              enabled: !1,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5,
            },
          },
          dataLabels: {
            name: {
              show: !0,
              fontSize: "16px",
              fontWeight: 600,
              offsetY: -10,
            },
            value: {
              show: !0,
              fontSize: "14px",
              offsetY: 4,
              formatter: function (e) {
                return e + "%"
              },
            },
            total: {
              show: !0,
              label: "Total",
              color: "#373d3f",
              fontSize: "16px",
              fontFamily: void 0,
              fontWeight: 600,
              formatter: function (e) {
                return totalProfitPercent + "%"
              },
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      colors,
      labels,
      legend: { show: !1 },
    }

  }

  componentDidMount() {
    // this.props.onGetBots(this.botsSuccess, this.onError)
    this.botsSuccess()
  }


  botsSuccess() {
    console.info('bots success', this.props);
    const bot = this.props.bots?.find(bot => bot._id == this.props.match.params.id)
    console.info('bots success1', bot);
    const coins = Object.keys(bot?.coin_details)

    let coinsArray = []
    coins?.forEach(coin => {
      if (coin && coin != 'USDT') {
        coinsArray.push(coin + '/USDT')
      }
    });

    this.props.onGetPrices(coinsArray, this.pricesSuccess, this.onError)
  }

  pricesSuccess() {
    // console.info('prices success');
    const bot = this.props.bots?.find(bot => bot._id == this.props.match.params.id)
    this.props.onGetBalance(bot?.api_id?._id, this.balanceSuccess, this.onError)
  }

  balanceSuccess() {
    // console.info('balance success');
    const bot = this.props.bots?.find(bot => bot._id == this.props.match.params.id)
    const coins = Object.keys(bot?.coin_details)
    const { balance, prices } = this.props

    let assets_sum_start = {}
    coins?.forEach(coin => {
      if (coin == 'USD' || coin == 'USDT') {
        assets_sum_start[coin] = bot?.coin_details[coin]
      }
      else {
        assets_sum_start[coin] = bot?.coin_details[coin] * prices[coin + '/USDT'].price
      }
    });

    let assets_sum_current = {}
    coins?.forEach(coin => {
      if (coin == 'USD' || coin == 'USDT') {
        assets_sum_current[coin] = balance[coin]
      }
      else {
        assets_sum_current[coin] = balance[coin] * prices[coin + '/USDT'].price
      }
    });


    let startingAssets = {}
    for (let coin in assets_sum_start) {
      startingAssets[coin] = {
        coin: coin,
        amount: bot?.coin_details[coin],
        USDT_value: assets_sum_start[coin]
      }
    }
    let startingAssetsSum = Object.values(assets_sum_start).reduce((x, i) => x + i);

    let currentAssets = {}
    for (let coin in assets_sum_current) {
      currentAssets[coin] = {
        coin: coin,
        amount: balance[coin],
        USDT_value: assets_sum_current[coin]
      }
    }
    let currentAssetsSum = Object.values(assets_sum_current).reduce((x, i) => x + i);


    let profitPercentages = {};
    for (let coin in assets_sum_start) {
      let profit = balance[coin] - bot?.coin_details[coin]
      console.info('profit', profit);
      profitPercentages[coin] = Math.round((profit / bot?.coin_details[coin]) * 10000) / 100
    }

    const totalProfitPercent = Math.round(((currentAssetsSum - startingAssetsSum) / startingAssetsSum) * 10000) / 100

    const profitPercentagesSum = Object.values(profitPercentages).reduce((x, i) => x + i);
    const relativeProfitPercentages = Object.values(profitPercentages).map(profit => Math.round((profit / profitPercentagesSum) * 10000) / 100);

    console.info('startingAssets', startingAssets);
    console.info('currentAssets', currentAssets);
    console.info('profitPercentages', profitPercentages);
    console.info('profitPercentagesSum', profitPercentagesSum);
    console.info('relativeProfitPercentages', relativeProfitPercentages);

    this.setState({
      loading: false,
      startingAssets,
      startingAssetsSum,
      currentAssets,
      currentAssetsSum,
      profitPercentages,
      totalProfitPercent,

      series: relativeProfitPercentages,
      labels: Object.keys(profitPercentages)
    })
  }


  onError(error) {
    console.error(error);
  }


  totalProfitPercent() {
    return this.state.totalProfitPercent
  }



  render() {
    const {
      currentAssets,
      currentAssetsSum,
      startingAssets,
      startingAssetsSum,
      profitPercentages
    } = this.state

    const currentAssetsArray = currentAssets ? Object.values(currentAssets) : null
    const profit = currentAssetsSum - startingAssetsSum
    const profitPercent = (profit / startingAssetsSum) * 100
    const isPositive = profit >= 0 ? true : false


    return (
      <React.Fragment>
        <Col xl="12">
        <h4 className="card-title mb-3">Balance & Earnings</h4>
          <Card>
            <CardBody>
              {this.state.loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-danger" role="status"></div>
                  <span className="ms-2">Loading...</span>
                </div>
              ) : (
                <React.Fragment>
                  <Row>
                    <Col lg="3">
                      <div className="mt-4">
                        <p className="mb-2">Current Balance</p>
                        <h4>{currentAssetsSum.toFixed(2)} USDT</h4>

                        {/*
                          <p className="text-muted mb-4">
                          {isPositive ? '+' : '-'} {profit.toFixed(2)} USDT ({profitPercent.toFixed(2)}%)
                          <i className={`mdi mdi-arrow-${isPositive ? 'up' : 'down'} ms-1 text-success`} />
                          </p>
                        */}
                      </div>

                      <div className="mt-4">
                        <p className="mb-2">Profit</p>
                        <h5 className="text-muted mb-4">
                          {isPositive ? '+' : ''} {profit.toFixed(2)} USDT ({profitPercent.toFixed(2)}%)
                          <i className={`mdi mdi-arrow-${isPositive ? 'up' : 'down'} ms-1 text-${isPositive ? 'success' : 'danger'}`} />
                        </h5>
                      </div>
                    </Col>

                    <Col lg="4" sm="6">
                      <div>
                        <div id="wallet-balance-chart" className="apex-charts">
                          <ReactApexChart
                            options={this.walletOptions()}
                            series={this.state.series}
                            type="radialBar"
                            height={202}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="4" sm="6" className="align-self-center">
                      {currentAssetsArray.map((asset, index) => {
                        return (
                          <div className="my-3" key={asset.coin}>
                            <h5 className="mb-1">
                              <i className="mdi mdi-circle align-middle font-size-10 me-2"
                                style={{ color: color(index) }}
                              />
                              {asset.amount} {asset.coin} = <span className="text-muted fw-light">${asset.USDT_value?.toFixed(2)}</span>
                            </h5>
                            <p className="text-muted mb-0">
                              {profitPercentages[asset.coin] >= 0 ? '+' : ''} {profitPercentages[asset.coin]}%
                              <i className={`mdi mdi-arrow-${isPositive ? 'up' : 'down'} ms-1 text-success`} />
                            </p>
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
    // return (
    //   <React.Fragment>
    //     <Col xl="8">
    //       <Card>
    //         <CardBody>
    //           <div className="float-end">
    //             <select className="form-select form-select-sm ms-2">
    //               <option value="1" defaultValue>
    //                 March
    //               </option>
    //               <option value="2">February</option>
    //               <option value="3">January</option>
    //               <option value="4">December</option>
    //             </select>
    //           </div>
    //           <h4 className="card-title mb-3">Wallet Balance</h4>

    //           <Row>
    //             <Col lg="4">
    //               <div className="mt-4">
    //                 <p>Available Balance</p>
    //                 <h4>$ 6134.39</h4>

    //                 <p className="text-muted mb-4">
    //                   {" "}
    //                   + 0.0012.23 ( 0.2 % ){" "}
    //                   <i className="mdi mdi-arrow-up ms-1 text-success"/>
    //                 </p>

    //                 <Row>
    //                   <Col xs="6">
    //                     <div>
    //                       <p className="mb-2">Income</p>
    //                       <h5>$ 2632.46</h5>
    //                     </div>
    //                   </Col>
    //                   <Col xs="6">
    //                     <div>
    //                       <p className="mb-2">Expense</p>
    //                       <h5>$ 924.38</h5>
    //                     </div>
    //                   </Col>
    //                 </Row>

    //                 <div className="mt-4">
    //                   <Link to="#" className="btn btn-primary btn-sm">
    //                     View more <i className="mdi mdi-arrow-right ms-1"/>
    //                   </Link>
    //                 </div>
    //               </div>
    //             </Col>

    //             <Col lg="4" sm="6">
    //               <div>
    //                 <div id="wallet-balance-chart" className="apex-charts">
    //                   <ReactApexChart
    //                     options={this.state.walletOptions}
    //                     series={this.state.series}
    //                     type="radialBar"
    //                     height={302}
    //                   />
    //                 </div>
    //               </div>
    //             </Col>

    //             <Col lg="4" sm="6" className="align-self-center">
    //               <div>
    //                 <p className="mb-2">
    //                   <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"/>{" "}
    //                   Ethereum
    //                 </p>
    //                 <h5>
    //                   4.5701 ETH ={" "}
    //                   <span className="text-muted font-size-14">$ 1123.64</span>
    //                 </h5>
    //               </div>

    //               <div className="mt-4 pt-2">
    //                 <p className="mb-2">
    //                   <i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning"/>{" "}
    //                   Bitcoin
    //                 </p>
    //                 <h5>
    //                   0.4412 BTC ={" "}
    //                   <span className="text-muted font-size-14">$ 4025.32</span>
    //                 </h5>
    //               </div>

    //               <div className="mt-4 pt-2">
    //                 <p className="mb-2">
    //                   <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"/>{" "}
    //                   Litecoin
    //                 </p>
    //                 <h5>
    //                   35.3811 LTC ={" "}
    //                   <span className="text-muted font-size-14">$ 2263.09</span>
    //                 </h5>
    //               </div>
    //             </Col>
    //           </Row>
    //         </CardBody>
    //       </Card>
    //     </Col>
    //   </React.Fragment>
    // )
  }
}

WalletBalance.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  bots: PropTypes.array,
  balance: PropTypes.object,
  prices: PropTypes.object,
  onGetBots: PropTypes.func,
  onGetBalance: PropTypes.func,
  onGetPrices: PropTypes.func,
}

const mapStateToProps = state => ({
  bots: state.bots.bots,
  balance: state.user.balance,
  prices: state.user.prices,
})

const mapDispatchToProps = dispatch => ({
  onGetBots: (onSuccess, onError) => dispatch(getBots(null, onSuccess, onError)),
  onGetBalance: (botID, onSuccess, onError) => dispatch(getBalance(botID, onSuccess, onError)),
  onGetPrices: (coins, onSuccess, onError) => dispatch(getPrices(coins, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(WalletBalance)))
