import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, NavItem, NavLink, Row, Col, Button } from "reactstrap"
import classnames from "classnames"

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

//import cryptoWalletColumns from "./cryptoWalletColumns"


const productData = [
  { id: 1, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
  { id: 2, idno:"#SK3336", pdate : "03 Mar, 2020",type:"Sell",coin:"Ethereum",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 3, idno:"#SK3226", pdate : "13 Jun, 2020",type:"Sell",coin:"Litecoin",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
  { id: 4, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Ethereum",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 5, idno:"#SK3226", pdate : "23 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 6, idno:"#SK3116", pdate : "03 Mar, 2020",type:"Sell",coin:"Ethereum",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
  { id: 7, idno:"#SK3336", pdate : "13 Mar, 2020",type:"Buy",coin:"Ethereum",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 8, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 9, idno:"#SK3226", pdate : "23 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 10, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Sell",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 11, idno:"#SK3226", pdate : "08 Mar, 2020",type:"Sell",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 12, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 13, idno:"#SK3336", pdate : "03 Mar, 2020",type:"Buy",coin:"Ethereum",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
  { id: 14, idno:"#SK3336", pdate : "07 Mar, 2020",type:"Sell",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 15, idno:"#SK3226", pdate : "13 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 16, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Ethereum",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
  { id: 17, idno:"#SK3336", pdate : "03 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
]


class WalletActivities extends Component {
  // const data = {
  //   columns: cryptoWalletColumns,
  //   rows: walletHistory,
  // }

  constructor(props) {
    super(props)
    this.state = {
      limit: 10,
      page: 0,
      columns: [
        {
          dataField: 'transaction_hash',
          text: 'ID'
        },
        {
          dataField: 'created_at',
          text: 'Date',
          formatter: (cellContent, row) => (
            new Date(row?.created_at)?.toLocaleString()
          )
        },
        {
          dataField: 'activity_type',
          text: 'Type'
        },
        {
          dataField: 'from_address',
          text: 'From'
        },
        {
          dataField: 'to_address',
          text: 'To'
        },
        {
          dataField: 'value',
          text: 'Amount',
          formatter: (cellContent, row) => (
            row?.value + ' SVC'
          )
        },
        {
          dataField: 'status',
          text: 'Status',
          formatter: (cellContent, row) => (
            (() => {
              switch (row?.status) {
                case 1:
                  return (<span className="text-success">Completed</span>)
                case 2:
                  return (<span className="text-danger">Cancelled</span>)
                default:
                  return (<span className="text-warning">Pending</span>)
              }
            })()
          )
        }
      ]
    }

    this.firstPage = this.firstPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.lastPage = this.lastPage.bind(this)
  }

  firstPage() {
    let newPage = 0;
    this.setState({ page: newPage })
    this.props.updatePage(newPage, this.state.limit)
  }

  previousPage() {
    if (this.state.page <= 0) {
      return;
    }
    let newPage = this.state.page - 1;
    this.setState({ page: newPage })
    this.props.updatePage(newPage, this.state.limit)
  }

  nextPage() {
    if (this.state.page >= (this.props.numTotal / this.state.limit) - 1) {
      return;
    }
    let newPage = this.state.page + 1;
    this.setState({ page: newPage })
    this.props.updatePage(newPage, this.state.limit)
  }

  lastPage() {
    let newPage = Math.floor(this.props.numTotal / this.state.limit);
    this.setState({ page: newPage })
    this.props.updatePage(newPage, this.state.limit)
  }



  render() {
    const { limit, page } = this.state
    const { numTotal } = this.props

    return (
      <Card>
        <CardBody>
          {/*
          <ul className="nav nav-tabs nav-tabs-custom">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Buy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                Sell
              </NavLink>
            </NavItem>
          </ul>
          */}

          <h4 className="card-title mb-4">Transaction History</h4>

          {
            this.props.walletHistory?.length && (
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  onClick={this.firstPage}
                  disabled={page <= 0}
                >
                  First
                </Button>
                <Button
                  onClick={this.previousPage}
                  disabled={page <= 0}
                >
                  Previous
                </Button>
                <p className="mb-0 mx-2">{(page * limit) + 1} - {(page + 1) * limit <= numTotal ? (page + 1) * limit : numTotal} of {numTotal}</p>
                <Button
                  onClick={this.nextPage}
                  disabled={page >= Math.floor((numTotal - 1) / limit)}
                >
                  Next
                </Button>
                <Button
                  onClick={this.lastPage}
                  disabled={page >= Math.floor((numTotal - 1) / limit)}
                >
                  Last
                </Button>
              </div>
            )
          }
          

          {this.props.walletHistory?.length ? (
            <div className="mt-4 table-responsive">
              <BootstrapTable keyField='_id'
                data={this.props.walletHistory}
                columns={this.state.columns} />
            </div>
          ) : (
            <p className="text-muted">You have no transaction history yet.</p>
          )}

        </CardBody>
      </Card>
    )
  }

}

WalletActivities.propTypes = {
  walletHistory: PropTypes.array,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
  updatePage: PropTypes.func,
  numTotal: PropTypes.num,
}

export default WalletActivities
