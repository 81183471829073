import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Profile extends Component {
    render() {
        return (
            <div>
                <Link to={"/login"}> <p> login </p></Link>
                <p> Profile </p>
            </div>
        )
    }
}

export default Profile
