import React, { Component } from "react"
import PropTypes from 'prop-types'

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { AvField, AvForm } from "availity-reactstrap-validation"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.png"

import { login, register } from "store/actions"

import * as CryptoJS from 'crypto-js'
import secret from 'secretConfig'

import Footer from './../../navigation/Footer';

import ReCaptcha from 'react-google-recaptcha';
import { recaptchaKey } from '../../helpers/api_helper';

class Register extends Component {

    constructor(props) {
      super(props)
      this.state = {
        loading: false,
        success: null,
        error: null,
        complete: false,

        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        referralCode: '',
        recaptcha: ''
      }

      this.onRecaptchaChange = this.onRecaptchaChange.bind(this)

      this.onClickRegister = this.onClickRegister.bind(this)
      this.onRegisterSuccess = this.onRegisterSuccess.bind(this)
      this.onRegisterFailure = this.onRegisterFailure.bind(this)
      this.redirectToDashboard = this.redirectToDashboard.bind(this)
    }

    componentDidMount() {
      let code = this.props.match.params.code
      if (code) {
        this.setState({ referralCode: code })
      }
    }

    onRecaptchaChange(value) {
      this.setState({ recaptcha: value })
    }

    onClickRegister(event, values) {
      const { password, confirmPassword } = this.state

      if (password !== confirmPassword) {
        this.setState({ error: 'Passwords do not match.' })
        return;
      }

      if (!password.match(/[A-Z]/) ||
          !password.match(/[a-z]/) ||
          !password.match(/[0-9]/) ||
          !password.match(/[!@#$%^&*()_+=-?]/)) {
        this.setState({ error: 'Password must include an uppercase and lowercase letter, a number, and a special character.' })
        return;
      }

      this.setState({ loading: true })

      const userData = {
        name: values.name,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        username: 'username',
        terms_cond: true,
        referral_code: values.referralCode,
        recaptcha_response: this.state.recaptcha,
      }
      this.props.onRegister(userData, this.onRegisterSuccess,this.onRegisterFailure)
      // this.onRegisterSuccess()
    }

    onRegisterSuccess(response) {
      this.setState({
        loading: false,
        success: 'Registration successful! Please check your email for verification.',
        error: null,
        complete: true,
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        referralCode: ''
      })
    }

    onRegisterFailure(response) {
      this.setState({
        loading: false,
        error: response.data?.msg
      })
    }

    redirectToDashboard() {
      this.props.history.push('/login')
    }

    render() {
      return (
        <React.Fragment>
          {/*
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="bx bx-home h2" />
            </Link>
          </div>
          */}

          <div className="account-pages my-1 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>

                  {/* <div style={{ width: '90px', margin: 'auto', marginBottom: '2rem' }}>
                    <img src={logo} width="100%" />
                  </div> */}

                  <Card className="overflow-hidden">

                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-4">
                          <div className="text-primary p-2">
                            {/* <h5 className="text-primary">Welcome to Talmud!</h5> */}
                            <div style={{ width: '90px', margin: 'auto' }}>
                                <img src={logo} className="img-fluid" />
                            </div>
                          </div>
                        </Col>
                        <Col className="col-3"></Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>

                    <CardBody className="pt-0">

                      {/*
                      <div className="auth-logo">
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={lightlogo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                        <Link to="/" className="auth-logo-dark">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      */}

                      <div className="p-3 pt-5">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.onClickRegister}
                        >
                          <h5 className="text-center mb-4">Register</h5>

                          {this.props.error && this.props.error ? (
                            <Alert color="danger">{this.props.error}</Alert>
                          ) : ''}


                          {!this.state.complete && (
                            <React.Fragment>
                              <div className="mb-3">
                                <AvField
                                  required
                                  type="name"
                                  name="name"
                                  label="Name"
                                  value={this.state.name}
                                  className="form-control"
                                  placeholder="Enter your name"
                                  onChange={(e) => this.setState({ name: e.target.value })}
                                />
                              </div>

                              <div className="mb-3">
                                <AvField
                                  required
                                  type="email"
                                  name="email"
                                  label="Email"
                                  value={this.state.email}
                                  className="form-control"
                                  placeholder="Email"
                                  onChange={(e) => this.setState({ email: e.target.value })}
                                />
                              </div>

                              <div className="mb-3">
                                <AvField
                                  required
                                  type="password"
                                  name="password"
                                  label="Password"
                                  value={this.state.password}
                                  placeholder="Password"
                                  onChange={(e) => this.setState({ password: e.target.value })}
                                />
                              </div>

                              <div className="mb-3">
                                <AvField
                                  required
                                  type="password"
                                  name="confirmPassword"
                                  label="Confirm Password"
                                  value={this.state.confirmPassword}
                                  placeholder="Confirm Password"
                                  onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                                />
                              </div>

                              <div className="mb-3">
                                <AvField
                                  type="text"
                                  name="referralCode"
                                  label="Referral Code"
                                  value={this.state.referralCode}
                                  placeholder="Referral Code"
                                  onChange={(e) => this.setState({ referralCode: e.target.value })}
                                />
                              </div>

                              <ReCaptcha
                                sitekey={recaptchaKey}
                                onChange={this.onRecaptchaChange}
                              />

                              <div className="my-3 d-grid">
                                <button
                                  className="btn talmud btn-block"
                                  type="submit"
                                >
                                  {this.state.loading ? (
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                                  ) : 'Register'}
                                </button>
                              </div>
                            </React.Fragment>
                          )}

                          {this.state.success && (
                            <Alert color="success" role="alert">
                              {this.state.success}
                            </Alert>
                          )}

                          {this.state.error && (
                            <Alert color="danger" role="alert">
                              {this.state.error}
                            </Alert>
                          )}

                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="mt-5 text-center">
                    <p>
                      {"Already have an account?"}
                      <Link
                        to="/login"
                        className="fw-medium text-primary"
                      >
                        {" "}
                        Sign In{" "}
                      </Link>{" "}
                    </p>
                  </div>

                  <div className="my-5 py-5"></div>
                </Col>
              </Row>
              <Footer auth={true}/>
            </Container>
          </div>
        </React.Fragment>
      )
    }
}

Register.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  error: PropTypes.any,
  onRegister: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onRegister: (userData, onSuccess,onError) => dispatch(register(userData, onSuccess,onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Register)))
