import React, { Component } from "react"
import PropTypes from 'prop-types'

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { AvField, AvForm } from "availity-reactstrap-validation"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"

import { setPassword } from "store/actions"

import * as CryptoJS from 'crypto-js'
import secret from 'secretConfig'
import Footer from './../../navigation/Footer';

import ReCaptcha from 'react-google-recaptcha';
import { recaptchaKey } from '../../helpers/api_helper';


class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,

      newPassword: '',
      newPasswordConfirm: '',
      hasSubmitted: false,
      recaptcha: ''
    }

    this.onRecaptchaChange = this.onRecaptchaChange.bind(this)

    this.resetPassword = this.resetPassword.bind(this)
    this.resetPasswordSuccess = this.resetPasswordSuccess.bind(this)
    this.resetPasswordError = this.resetPasswordError.bind(this)
  }

  onRecaptchaChange(value) {
    this.setState({ recaptcha: value })
  }

  resetPassword() {
    const { newPassword, newPasswordConfirm } = this.state

    if (newPassword !== newPasswordConfirm) {
      this.setState({ error: 'New Passwords do not match.' })
      return;
    }

    if (!newPassword.match(/[A-Z]/) ||
        !newPassword.match(/[a-z]/) ||
        !newPassword.match(/[0-9]/) ||
        !newPassword.match(/[!@#$%^&*()_+=-?]/)) {
      this.setState({ error: 'Password must include an uppercase and lowercase letter, a number, and a special character.' })
      return;
    }

    this.setState({
      loading: true,
      error: null
    })

    let params = {
      key: this.props.match.params?.key,
      password: newPassword,
      recaptcha_response: this.state.recaptcha
    }
    console.log(params);
    this.props.onSetPassword(params, this.resetPasswordSuccess, this.resetPasswordError)
  }

  resetPasswordSuccess(response) {
    this.setState({
      loading: false,
      success: response?.msg,
      hasSubmitted: true
    })
  }

  resetPasswordError(response) {
    this.setState({
      loading: false,
      error: response?.data?.msg
    })
  }


  render() {
    // let isPasswordValid = this.state.password.trim() && this.state.password.includes('@')
    let isPasswordValid = true

    return (
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">

                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome to Talmud!</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="p-3 pt-5">
                    <h6 className="text-center mb-4">Reset Password</h6>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={this.onClickLogin}
                    >
                      {this.state.success && (
                        <Alert color="success" role="alert">
                          {this.state.success}
                        </Alert>
                      )}

                      {!this.state.hasSubmitted && (
                        <React.Fragment>
                          <div className="mb-3">
                            <AvField
                              required
                              type="password"
                              name="newPassword"
                              label="New Password"
                              value={this.state.newPassword}
                              className="form-control"
                              placeholder="New Password"
                              onChange={(e) => this.setState({ newPassword: e.target.value })}
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              required
                              type="password"
                              name="newPasswordConfirm"
                              label="New Password Confirm"
                              value={this.state.newPasswordConfirm}
                              className="form-control"
                              placeholder="New Password Confirm"
                              onChange={(e) => this.setState({ newPasswordConfirm: e.target.value })}
                            />
                          </div>

                          <ReCaptcha
                            sitekey={recaptchaKey}
                            onChange={this.onRecaptchaChange}
                          />

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              onClick={this.resetPassword}
                              disabled={!isPasswordValid}
                            >
                              {this.state.loading ? (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                              ) : 'Reset Password'}
                            </button>
                          </div>
                        </React.Fragment>
                      )}

                      {this.state.error && (
                        <Alert color="danger" role="alert" className="mt-3">
                          {this.state.error}
                        </Alert>
                      )}

                      <div className="mt-4 text-center">
                        <Link to="/login" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Back to Login
                        </Link>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Footer auth={true}/>
        </Container>
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  error: PropTypes.any,
  onSetPassword: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onSetPassword: (params, onSuccess, onError) => dispatch(setPassword(params, onSuccess, onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ForgotPassword)))
