import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import Select from "react-select"

import Breadcrumbs from "navigation/Breadcrumb"

import axios from 'axios';

import {
  getAPIs,
  getExchanges,
  getBalance,
  getPrices,
  getCoins,
  getPairs,
  getBots,
  editBot,
} from "store/actions"



// --------------------
// MARKET DATA

// const ccxt = require('ccxt');
// const paradise = new ccxt.paradise();
// const binance = new ccxt.binance();
// let markets;
// let prices;

// --------------------
// COLORS

const green = '#5fcb80';
const red = '#f0616e';

// --------------------

import { BOT_DIFFERENCE } from './bot_settings'




const icons = {
  BTC: 'mdi-bitcoin',
  ETH: 'mdi-ethereum',
  XRP: 'mdi-bitcoin',
  USDT: 'mdi-currency-usd',
  BUSD: 'mdi-currency-usd',
  USD: 'mdi-currency-usd',
  JPY: 'mdi-currency-jpy',
}

let paradiseData;

class EditBot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      isMenu: false,

      name: '',
      selectedExchange: null,
      selectedCoins: [],
      prices: null,
      pairs: [],
      filteredPairs: [],
      selectedPairs: null,
      totalBalance: 9148.23,
      delta: null,

      assetsUSDT: null,
      assetsAverage: null,

      loadingBalance: false,

      isBotRunning: false,

      loading: false,
      error: null,
      insufficientBalances: null
    }

    this.fetchPrices = this.fetchPrices.bind(this)
    this.fetchPricesSuccess = this.fetchPricesSuccess.bind(this)
    this.fetchPricesError = this.fetchPricesError.bind(this)
    this.onGetBalanceSuccess = this.onGetBalanceSuccess.bind(this)
    this.onGetBalanceError = this.onGetBalanceError.bind(this)

    this.toggleTab = this.toggleTab.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.calculatePairs = this.calculatePairs.bind(this)
    this.calculateAssetsAverage = this.calculateAssetsAverage.bind(this)
    this.coinDifference = this.coinDifference.bind(this)
    this.displayCoinDifference = this.displayCoinDifference.bind(this)
    this.isAssetSufficient = this.isAssetSufficient.bind(this)
    this.calcBalanceErrors = this.calcBalanceErrors.bind(this)
    this.formatBalanceColor = this.formatBalanceColor.bind(this)
    this.formatColumnsWidth = this.formatColumnsWidth.bind(this)
    this.formatCoinList = this.formatCoinList.bind(this)

    this.updateBot = this.updateBot.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)

    this.redirectToAPIs = this.redirectToAPIs.bind(this)
  }

  componentDidMount() {

    const { bots, onGetBots } = this.props;
    // if (!bots || !bots.length) {
    this.props.onGetBots((bots) => {

      const bot = bots.data?.find(bot => bot._id == this.props.match.params.id)

      if (bot?.status) {
        this.setState({
          isBotRunning: true
        })
        return;
      }

      this.props.onGetCoins()
      this.setState({
        loadingBalance: true
      })
      this.props.onGetBalance(bot.api_id?._id, () => {
        this.props.onGetPairs((pairs) => {
          this.props.onGetAPIs(() => {
            const api = bot?.api_id ? this.props.exchangeAPIs.find(api => api._id == bot.api_id._id) : null

            let selectedCoins1 = [];
            bot.trade_coins.map((item, index) => {
              selectedCoins1.push({ label: item?.short_name, value: item._id })
            })
            // this.handleCoinSelect(selectedCoins1);
            this.calculatePairs(selectedCoins1);

            let selectedPairs = [];
            bot.pairs.map((pair, index) => {
              selectedPairs.push({ label: pair, value: pair })
            })
            // let selectedpairs1 = [];
            // if(this.state.pairs.length){

            //   console.warn("this.state.pairs[0].options ",this.state.pairs[0].options);
            // }
            this.setState({
              selectedExchange: { label: api?.name + ` (${bot.api_id.exchange_id?.name})`, value: api._id },
              name: bot.name,
              delta: bot.delta * 100,
              selectedCoins: selectedCoins1,
              selectedPairs: selectedPairs,
              loadingBalance: false
            }, () => {
              this.fetchPrices(selectedCoins1)
            })

          })
        })

      }, this.onGetBalanceError);

    }, (err) => { })
    // }








    // this.loadMarkets()
  }

  // async loadMarkets() {
  //   markets = await binance.load_markets();
  //   console.warn(markets);
  //   // markets = await fetch('https://api.paradise.exchange/spot/api/v3.2/market_summary', {
  //   //   method: 'GET',
  //   //   mode: 'cors',
  //   //   headers: {
  //   //     'Content-Type': 'application/json'
  //   //   }
  //   // }).then(response => {
  //   //   console.warn(response);
  //   // });
  // }

  async fetchPrices(coins) {
    if (!coins || coins.length < 2) return;

    let coinsArray = []
    coins.forEach(coin => {
      if (coin.label && coin.label != 'USDT') {
        coinsArray.push(coin.label + '/USDT')
      }
    });

    this.props.onGetPrices(coinsArray, this.fetchPricesSuccess, this.fetchPricesError)

    // let prices = await paradise.fetchTrades(coinsArray, undefined, 20, {});
    // let prices = await binance.fetchTickers(coinsArray);
    // this.setState({ prices })
  }

  fetchPricesSuccess() {
    this.calculateAssetsAverage()
  }

  fetchPricesError() {
    this.calculateAssetsAverage()
  }





  formatExchanges() {
    const { exchangeAPIs } = this.props;
    if (!exchangeAPIs || !exchangeAPIs.length) return []

    return [
      {
        label: '',
        options: exchangeAPIs.map(api => {
          return { label: api.name + ` (${api.exchange_id?.name})`, value: api._id }
        })
      }
    ]
  }

  formatCoinList() {
    const { coins } = this.props;
    if (!coins || !coins.length) return []

    return [
      {
        label: '',
        options: coins.map(coin => {
          return { label: coin.short_name, value: coin._id }
        })
      }
    ]
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }))
  }

  calculatePairs(selectedCoins) {
    console.warn("calculatePairs selectedCoins ",selectedCoins )
    if (!selectedCoins || selectedCoins.length < 2 || !this.props.pairs?.length) return;

    // let existingPairs = {};
    // let coinPairs = [];
    //
    // selectedCoins.forEach((firstCoin, firstIndex) => {
    //   selectedCoins.forEach((secondCoin, secondIndex) => {
    //
    //     if (firstCoin === secondCoin) return;
    //
    //     // Sort pair name alphabetically
    //     let pairLabel = firstCoin.label + '/' + secondCoin.label;
    //     let pairValue = firstCoin.label + '-' + secondCoin.label;
    //     if (secondCoin.value < firstCoin.value) {
    //       pairLabel = secondCoin.label + '/' + firstCoin.label;
    //       pairValue = secondCoin.label + '-' + firstCoin.label;
    //     }
    //
    //     if (existingPairs[pairLabel]) return;
    //     else {
    //       existingPairs[pairLabel] = true;
    //       coinPairs.push({ label: pairLabel, value: pairValue });
    //     }
    //
    //   });
    // });

    // console.warn('selectedCoins 1', selectedCoins);

    const { pairs } = this.props

    let allPossiblePairs = {}
    selectedCoins.forEach(firstCoin => {
      selectedCoins.forEach(secondCoin => {
        allPossiblePairs[`*${firstCoin.label}-${secondCoin.label}*`] = true
        allPossiblePairs[`*${secondCoin.label}-${firstCoin.label}*`] = true
      });
    });

    allPossiblePairs = Object.keys(allPossiblePairs).join(',')

    // console.warn('allPossiblePairs', allPossiblePairs);

    let coinPairs = pairs.filter(pair => allPossiblePairs.includes(`*${pair.pair}*`))

    // console.warn('coinPairs', coinPairs);

    coinPairs.sort((first, second) => first.pair < second.pair);

    let pairLabels = [{
      label: '',
      options: coinPairs.map(pair => {
        return {
          label: pair.pair.replace('-', '/'),
          value: pair._id
        }
      })
    }]

    this.setState({ pairs: pairLabels })

    return pairLabels[0].options;
  }


  calculateAssetsAverage() {
    const { prices } = this.props
    const coins = this.state.selectedCoins?.map(coin => coin.label)
    const { balance } = this.props

    let error = '';
    if (!coins || !coins.length) {
      error += 'No coins. '
    }
    if (!prices) {
      error += 'No prices. '
    }
    if (!balance) {
      error += 'No balance. '
    }

    if (error.trim()) {
      this.setState({ error })
      return;
    }
    else {
      this.setState({ error: null })
    }

    let assetsUSDT = {}
    coins.forEach(coin => {
      if (coin == 'USD' || coin == 'USDT') {
        assetsUSDT[coin] = balance[coin]
      }
      else {
        assetsUSDT[coin] = balance[coin] * prices[coin + '/USDT']?.price
      }
    });

    let values = Object.values(assetsUSDT)
    let total = values.reduce((x, i) => x + i);
    let assetsAverage = total / values.length;

    this.setState({ assetsUSDT, assetsAverage })

    this.calcBalanceErrors()
    // setTimeout(() => {
    // }, 100)
  }

  coinDifference(coin) {
    const { assetsUSDT, assetsAverage } = this.state

    let difference = assetsUSDT?.[coin] / assetsAverage;
    return difference - 1;
  }

  displayCoinDifference(coin) {
    let difference = this.coinDifference(coin) * 100;
    if (isNaN(difference)) return ' (-- %)'
    let plus = difference > 0 ? '+' : '';
    return ` (${plus}${difference.toFixed(1)}%)`
  }

  isAssetSufficient(coin) {
    let difference = this.coinDifference(coin)

    // e.g. if difference is 0.3 (30%), is the balance
    // between 0.7 (70%) and 1.3 (130%) of average
    return Math.abs(difference) <= BOT_DIFFERENCE;
  }

  calcBalanceErrors() {
    const { assetsUSDT } = this.state

    let errors = [];
    Object.keys(assetsUSDT).forEach(coin => {
      if (!this.isAssetSufficient(coin)) {
        errors.push(`${coin} balance is more than 30% beyond the average.`);
      }
    });

    if (!errors.length) errors = "";

    this.setState({ insufficientBalances: errors })
  }

  formatBalanceColor(coin) {
    return
  }

  formatColumnsWidth() {
    const { selectedCoins } = this.state;
    if (!selectedCoins || selectedCoins.length <= 1) return '12';
    else if (selectedCoins.length === 2) return '6';
    else return '4';
  }

  handleExchangeSelect = selectedExchange => {
    // console.warn("selectedExchange ",selectedExchange);
    this.setState({
      selectedExchange,
      loadingBalance: true
    })
    this.props.onGetBalance(selectedExchange.value, this.onGetBalanceSuccess, this.onGetBalanceError)
  }
  handleCoinSelect = async (selectedCoins, context) => {
    this.setState({ insufficientBalances: null })
    if (this.state.selectedCoins && this.state.selectedCoins.length >= 10) {
      if (context.action === 'remove-value') {
        let localselectedCoins = this.state.selectedCoins.filter(function (obj) {
          return obj.label !== context.removedValue.label;
        });
        this.setState({
          // error: this.props.t("You can not choose more than 10 coins"),
          selectedCoins: localselectedCoins
        })
      }
      else if (context.action === 'clear') {
        this.setState({
          // error: this.props.t("You can not choose more than 10 coins"),
          selectedCoins: [],
          selectedPairs: null
        })
      }
      else {
        // this.setState({
        //   error: this.props.t("You can not choose more than 10 coins")
        // })
      }
    }
    else {
      await this.fetchPrices(selectedCoins)
      this.calculatePairs(selectedCoins)
      this.setState({
        selectedCoins: selectedCoins ? selectedCoins : [],
        selectedPairs: null,
        // error: ''
      })
    }
  }
  handlePairSelect = selectedPairs => {
    console.warn("selectedPairs ",selectedPairs);
    this.setState({ selectedPairs })
  }


  onGetBalanceSuccess(response) {
    this.setState({ loadingBalance: false })
  }

  onGetBalanceError(response) {
    if (response?.data) {
      this.setState({
        error: response?.data.msg,
        loadingBalance: false
      })
    } else {

      this.setState({
        error: response.error,
        loadingBalance: false
      })
    }
  }

  updateBot() {
    const {
      name,
      delta,
      selectedExchange,
      selectedCoins,
      selectedPairs,
    } = this.state
    const { balance } = this.props


    if (!name.trim()) {
      this.setState({ error: 'Please enter a name.' })
      return;
    }
    if (!delta) {
      this.setState({ error: 'Please enter a delta amount (% per trade).' })
      return;
    }
    if (!selectedExchange) {
      this.setState({ error: 'Please select an exchange.' })
      return;
    }
    if (!selectedCoins || selectedCoins.length < 2) {
      this.setState({ error: 'Please select two or more coins.' })
      return;
    }
    if (!selectedPairs || !selectedPairs.length) {
      this.setState({ error: 'Please select one or more pairs.' })
      return;
    }


    this.setState({ loading: true });

    let coin_details = {};
    selectedCoins.forEach(coin => {
      coin_details[coin.label] = balance[coin.label]
    })

    let newBot = {
      id: this.props.match.params.id,
      name: name,
      api_id: this.state.selectedExchange.value,
      coins: selectedCoins.map(coin => coin.value),
      pairs: selectedPairs.map(pair => pair.label?.replace('/', '-')),
      delta: delta / 100,
      coin_details
    }
    // console.warn("newBot ", JSON.stringify(newBot));

    this.props.onEditBot(newBot, this.onSuccess, this.onError);
  }

  onSuccess() {
    this.setState({ loading: false, success: 'Bot successfully updated.' })
    // this.props.history.push('/bot-list')
  }

  onError(response) {
    if (response?.data) {
      this.setState({
        error: response?.data.msg,
        loading: false
      })
    } else {
      this.setState({
        loading: false,
        error: response.msg
      })
    }
  }



  redirectToAPIs() {
    this.props.history.push('/new-api')
  }

  render() {
    const {
      selectedPairs,
      selectedCoins,
      exchangesDisplay,
      insufficientBalances,
      assetsUSDT,
      assetsAverage
    } = this.state;
    const { balance } = this.props;

    // let firstCoin, secondCoin;
    // if (selectedPairs) {
    //   [firstCoin, secondCoin] = selectedPairs.label.split('/');
    // }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Talmud | Edit Bot</title>
          </MetaTags>
          <Container fluid>

            <Breadcrumbs
              title="My Bots"
              breadcrumbItem="Edit Bot"
              parent="/bot-list"
            />

            <Card className="mx-auto" style={{ maxWidth: '720px' }}>
              <CardBody>
                {this.state.selectedExchange && (
                  <div className="float-end">
                    <Dropdown
                      isOpen={this.state.isMenu}
                      toggle={this.toggleMenu}
                    >
                      <DropdownToggle
                        type="button"
                        tag="button"
                        className="btn btn-light"
                      >
                        <i className="mdi mdi-wallet me-1" />
                        <span className="d-none d-sm-inline-block ms-1">
                          Wallet Balance{" "}
                          <i className="mdi mdi-chevron-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end dropdown-menu-md"
                        style={{ maxHeight: '600px', overflowY: 'auto' }}
                      >
                        <div className="dropdown-item-text">
                          <div>
                            <p className="text-muted mb-2">
                              Available Balance
                            </p>
                          </div>
                        </div>

                        <DropdownItem divider />

                        {balance && Object.keys(balance).map(coin => {
                          return balance[coin] ? (
                            <DropdownItem href="#" key={coin}>
                              {coin}<span className="float-end">{balance[coin]}</span>
                            </DropdownItem>
                          ) : ''
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}

                <h4 className="card-title mb-4">Edit Bot</h4>

                <div className="crypto-buy-sell-nav">
                  <Form>

                    {this.state.loadingBalance ? (
                      <div className="p-4">
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                        <span>Fetching bot data...</span>
                      </div>
                    ) : ''}

                    {this.state.isBotRunning ? (
                      <Alert color="warning" role="alert" className="text-center">
                        Bot is currently running. Please stop bot before editing.
                      </Alert>
                    ) : (
                      <React.Fragment>
                        {this.state.selectedExchange && (
                          <React.Fragment>
                            <div className="mb-3">
                              <Label>Exchange</Label>
                              <Select
                                isDisabled={true}
                                value={this.state.selectedExchange}
                                onChange={this.handleExchangeSelect}
                                options={this.formatExchanges()}
                                classNamePrefix="select2-selection"
                                readOnly
                              />
                            </div>

                            <div className="mb-3">
                              <Label>Bot Name</Label>
                              <Input type="text" className="form-control" readOnly
                                maxLength={30}
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })} />
                            </div>

                            <div className="mb-3">
                              <Label>Delta per Trade (%)</Label>
                              <Input type="number" className="form-control"
                                value={this.state.delta}
                                onChange={(e) => this.setState({ delta: parseFloat(e.target.value) })}
                                onWheel={(e) => e.target.blur()} />
                            </div>

                            <div className="mb-3 select2-container">
                              <label className="control-label">
                                Select Coins
                              </label>
                              <Select
                                value={this.state.selectedCoins}
                                isMulti={true}
                                onChange={this.handleCoinSelect}
                                options={this.formatCoinList()}
                                classNamePrefix="select2-selection"
                              />
                              <label className="text-muted">You can choose maximum 10 coins</label>
                            </div>

                            {(selectedCoins?.length >= 2) && (
                              <React.Fragment>
                                <Label>Balance</Label>
                                <Row>
                                  <p><span className="text-primary">Assets Average:</span> {this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + ' USDT'}</p>
                                </Row>

                                <Row className="mb-3">
                                  {selectedCoins.map(coin => {
                                    return (
                                      <Col sm={this.formatColumnsWidth()} key={coin.value}>
                                        <Label className="card-radio-label mb-2">
                                          <div className="card-radio">
                                            <div className="d-flex justify-content-start align-items-center">
                                              <i className={`mdi ${icons[coin.label]} font-size-24 text-warning align-middle me-2`} />{" "}
                                              <span>{coin.label}</span>
                                              <span style={{
                                                marginLeft: 'auto',
                                                fontWeight: '400',
                                                color: this.isAssetSufficient(coin.label) ? green : red
                                              }}>{balance[coin.label] + this.displayCoinDifference(coin.label)}</span>
                                            </div>
                                          </div>
                                        </Label>
                                      </Col>
                                    )
                                  })}
                                </Row>
                              </React.Fragment>
                            )}

                            {insufficientBalances && insufficientBalances.map((error, index) => {
                              return (
                                <Alert color="danger" role="alert" key={index}>
                                  {error}
                                </Alert>
                              )
                            })}

                            {insufficientBalances && (
                              <Alert color="danger" role="alert">
                                Please adjust your portfolio balances and return to this page.
                              </Alert>
                            )}

                            <div className="mb-3 select2-container">
                              <label className="control-label">
                                Select Pair
                              </label>
                              <Select
                                value={selectedPairs}
                                isMulti={true}
                                onChange={this.handlePairSelect}
                                options={this.state.pairs}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}


                    {this.state.error && (
                      <Alert color="danger" role="alert">
                        {this.state.error}
                      </Alert>
                    )}
                    {this.state.success && (
                      <Alert color="success" role="alert">
                        {this.state.success}
                      </Alert>
                    )}

                    {!this.state.isBotRunning && this.props.exchangeAPIs?.length ? (
                      <div className="text-center mt-4">
                        <Alert color="warning" role="alert">
                          <span className="fw-bold">Warning:</span> Bots cannot be edited while the bot is running.
                        </Alert>
                        <Button type="button" color="success"
                          onClick={this.updateBot}
                          disabled={
                            !this.state.selectedExchange ||
                              !this.state.name.trim() ||
                              !this.state.delta ||
                              this.state.selectedCoins && !this.state.selectedCoins.length ||
                              !this.state.selectedPairs ||
                              this.state.insufficientBalances != "" ? true : false
                          }
                        >
                          {this.state.loading ? (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                          ) : 'Update Bot'}
                        </Button>
                      </div>
                    ) : ''}

                  </Form>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EditBot.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  exchangeAPIs: PropTypes.array,
  balance: PropTypes.object,
  prices: PropTypes.object,
  coins: PropTypes.array,
  pairs: PropTypes.array,
  exchanges: PropTypes.array,
  onGetAPIs: PropTypes.func,
  onGetBalance: PropTypes.func,
  onGetPrices: PropTypes.func,
  onGetCoins: PropTypes.func,
  onGetPairs: PropTypes.func,
  onEditBot: PropTypes.func,
  bots: PropTypes.array,
  onGetBots: PropTypes.func,
  match: PropTypes.any,
}

const mapStateToProps = state => ({
  exchangeAPIs: state.exchangeAPI.exchangeAPIs,
  balance: state.user.balance,
  prices: state.user.prices,
  coins: state.bots.coins,
  pairs: state.bots.pairs,
  bots: state.bots.bots,
})

const mapDispatchToProps = dispatch => ({
  onGetAPIs: (onFetch) => dispatch(getAPIs(onFetch)),
  onGetBalance: (botID, onSuccess, onError) => dispatch(getBalance(botID, onSuccess, onError)),
  onGetPrices: (coins, onSuccess, onError) => dispatch(getPrices(coins, onSuccess, onError)),
  onGetCoins: (onFetch) => dispatch(getCoins(onFetch)),
  onGetPairs: (onFetch) => dispatch(getPairs(onFetch)),
  onEditBot: (bot, onSuccess, onError) => dispatch(editBot(bot, onSuccess, onError)),
  onGetBots: (onSuccess, onError) => dispatch(getBots(null, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(EditBot)))
