import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getExchanges,
  createAPI
} from "store/actions"


class NewAPI extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedExchange: null,
      selectedExchangeName: null,
      name: null,
      apiKey: null,
      apiSecret: null,

      loading: false,
      error: null
    }
    this.onFetchExchanges = this.onFetchExchanges.bind(this)
    this.onExchangeChange = this.onExchangeChange.bind(this)
    this.addAPI = this.addAPI.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)
  }

  componentDidMount() {
    this.props.onGetExchanges(this.onFetchExchanges)
  }

  onFetchExchanges() {
    if (this.props.exchanges && this.props.exchanges.length === 1) {
      this.setState({
        selectedExchange: this.props.exchanges[0]._id,
        selectedExchangeName: this.props.exchanges[0].name
      })
    }
  }


  onExchangeChange(e) {
    let name = e.target.options[e.target.selectedIndex].text;
    this.setState({
      selectedExchange: e.target.value,
      selectedExchangeName: name
    })
  }

  addAPI() {
    const { selectedExchange, name, apiKey, apiSecret } = this.state;

    if ( !selectedExchange?.trim() || !name?.trim() || !apiKey?.trim() || !apiSecret?.trim() ) {
      this.setState({ error: 'Please enter all fields.' })
      return;
    }

    this.setState({ loading: true })

    let newAPI = {
      exchange_id: this.state.selectedExchange,
      name: this.state.name,
      api_key: this.state.apiKey,
      api_secret: this.state.apiSecret
    }
    this.props.onCreateAPI(newAPI, this.onSuccess, this.onError)
  }

  onSuccess(response) {
    this.setState({ loading: false })
    this.props.history.push('/my-apis')
  }

  onError(response) {
    this.setState({
      error: response.msg,
      loading: false
    })
  }


  render() {
    const { exchanges } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Talmud | Connect Exchange API</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="My APIs" breadcrumbItem="Connect New API" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    <h4 className="card-title mb-4">Connect Exchange API</h4>

                    <div className="crypto-buy-sell-nav">
                      <Form>

                        <div className="mb-3">
                          <Label>Nickname:</Label>
                          <Input type="text" className="form-control"
                            onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>

                        <div className="mb-3">
                          <Label>Exchange:</Label>
                          <select className="form-select form-control"
                            defaultValue={this.state.selectedExchange}
                            onChange={this.onExchangeChange}
                          >
                            {exchanges && exchanges.length ? exchanges.map(exchange => {
                              return (
                                <option
                                  key={exchange._id}
                                  value={exchange._id}
                                >{exchange.name}</option>
                              )
                            }) : ''}
                          </select>
                        </div>

                        <div className="mb-3">
                          <Label>API Key:</Label>
                          <Input type="text" className="form-control"
                            onChange={(e) => this.setState({ apiKey: e.target.value })} />
                        </div>

                        <div className="mb-3">
                          <Label>API Secret:</Label>
                          <Input type="text" className="form-control"
                            onChange={(e) => this.setState({ apiSecret: e.target.value })} />
                        </div>

                        {this.state.error && (
                          <Alert color="danger" role="alert">
                            {this.state.error}
                          </Alert>
                        )}

                        <div className="text-center mt-4">
                          <Button type="button" color="success"
                            onClick={this.addAPI}
                          >
                          {this.state.loading ? (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                          ) : 'Add Exchange API'}
                          </Button>
                        </div>

                      </Form>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

NewAPI.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  exchanges: PropTypes.array,
  onGetExchanges: PropTypes.func,
  onCreateAPI: PropTypes.func,
}

const mapStateToProps = state => ({
  exchanges: state.exchangeAPI.exchanges,
})

const mapDispatchToProps = dispatch => ({
  onGetExchanges: (onFetch) => dispatch(getExchanges(onFetch)),
  onCreateAPI: (api, onSuccess, onError) => dispatch(createAPI(api, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(NewAPI)))
