import PropTypes from 'prop-types'
import React, { Component } from "react"
import ReactDrawer from 'react-drawer'

import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "./TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "./TopbarDropdown/NotificationDropdown"
import ProfileMenu from "./TopbarDropdown/ProfileMenu"
// import RightSidebar from '../CommonForBoth/RightSidebar'

import megamenuImg from "../assets/images/megamenu-img.png"
import user from "../assets/images/user.png"


// import images
import github from "../assets/images/brands/github.png"
import bitbucket from "../assets/images/brands/bitbucket.png"
import dribbble from "../assets/images/brands/dribbble.png"
import dropbox from "../assets/images/brands/dropbox.png"
import mail_chimp from "../assets/images/brands/mail_chimp.png"
import slack from "../assets/images/brands/slack.png"


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
    }
    this.toggleFullscreen = this.toggleFullscreen.bind(this)
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  toggleMenu() {
    document.body.classList.toggle("vertical-collpsed")
    document.body.classList.toggle("sidebar-enable")
  }


  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">

            <div className="d-flex">
              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>

              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("Search") + "..."}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}
            </div>

            <div className="d-flex">
              {/*
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch })
                  }}
                  type="button"
                  className="btn header-item noti-icon"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              */}

              {/* <LanguageDropdown /> */}
              {/*
              <NotificationDropdown />
              */}

              {/*
              <Link
              //  href="https://ceewen.xyz/tradeplus_frontend/profile"
                to="/profile"
                className="btn d-inline-flex align-items-center header-item"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={user}
                  alt="Header Avatar"
                />
                <span className="d-none d-xl-inline-block ms-1">
                  {this.state.name}
                </span>
              </Link>
              */}


              <ProfileMenu />


            </div>
          </div>
        </header>
      </React.Fragment>
    )
  }
}


Header.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Header)
