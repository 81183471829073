import React, { Component } from "react"
import { Pie } from "react-chartjs-2"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Row,
} from "reactstrap"


class PieChart extends Component {

  constructor(props) {
    super(props)

    this.state = {
      assetsTotalUSDT: null
    }

    this.calcAssetsBalance = this.calcAssetsBalance.bind(this)
  }

  componentDidMount() {
    this.calcAssetsBalance()
  }

  calcAssetsBalance() {
    const { assets } = this.props

    if (!assets || !assets.length) return;

    let assetsTotalUSDT = 0;
    assets.forEach(asset => {
      assetsTotalUSDT += asset.coin == 'USDT' ? asset.amount : asset.amount * asset.price
    });
    this.setState({ assetsTotalUSDT })
  }

  render() {
    const { assets } = this.props
    console.log('assets', assets);

    const data = {
      labels: assets.map(asset => asset.coin),
      datasets: [
        {
          data: assets.map(asset => Math.round((asset.coin == 'USDT' ? asset.amount : asset.amount * asset.price) * 100) / 100),
          backgroundColor: ["#e9ae60", "#5188db"],
          hoverBackgroundColor: ["#f0bd79", "#679dee"],
          hoverBorderColor: "#fff",
        },
      ],
    }

    return (
      <Row className="justify-content-center align-items-center">
        <div className="d-flex flex-column" style={{ width: '240px' }}>
          <h3 className="text-center">B2Z</h3>
          <Pie width={100} height={100} data={data} />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100%', width: '150px' }}
        >
          <h5 className="mb-4">Balance</h5>
          {assets.map(asset => {
            return (
              <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }} key={asset.coin}>
                <b className="my-0">{asset.amount} {asset.coin}</b>
                <p className="my-0">{Math.round(((asset.coin == 'USDT' ? asset.amount : asset.amount * asset.price) / this.state.assetsTotalUSDT) * 1000) / 10}%</p>
              </div>
            )
          })}
        </div>
      </Row>
    )
  }
}

PieChart.propTypes = {
  isComponent: PropTypes.bool,
  t: PropTypes.any,
  history: PropTypes.any,
  assets: PropTypes.any
}

export default PieChart
