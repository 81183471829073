import {
  GET_BOTS,
  GET_BOTS_FAIL,
  GET_BOTS_SUCCESS,
  GET_COINS,
  GET_COINS_FAIL,
  GET_COINS_SUCCESS,
  GET_PAIRS,
  GET_PAIRS_FAIL,
  GET_PAIRS_SUCCESS,
  CREATE_BOT,
  CREATE_BOT_FAIL,
  CREATE_BOT_SUCCESS,
  EDIT_BOT,
  EDIT_BOT_FAIL,
  EDIT_BOT_SUCCESS,
  START_STOP_BOT,
  START_STOP_BOT_FAIL,
  START_STOP_BOT_SUCCESS,
  UPDATE_BOT,
  UPDATE_BOT_FAIL,
  UPDATE_BOT_SUCCESS,
  DELETE_BOT,
  DELETE_BOT_FAIL,
  DELETE_BOT_SUCCESS,
  GET_OPEN_ORDERS,
  GET_OPEN_ORDERS_FAIL,
  GET_OPEN_ORDERS_SUCCESS,
  GET_SELECTED_COIN_BAL,
  GET_SELECTED_COIN_BAL_FAIL,
  GET_SELECTED_COIN_BAL_SUCCESS,
} from "./actionTypes"



export const getBots = (page, onSuccess, onError) => ({
  type: GET_BOTS,
  payload: page,
  onSuccess,
  onError
})

export const getBotsSuccess = orders => ({
  type: GET_BOTS_SUCCESS,
  payload: orders,
})

export const getBotsFail = error => ({
  type: GET_BOTS_FAIL,
  payload: error,
})



export const getCoins = page => ({
  type: GET_COINS,
  payload: page,
})

export const getCoinsSuccess = coins => ({
  type: GET_COINS_SUCCESS,
  payload: coins,
})

export const getCoinsFail = error => ({
  type: GET_COINS_FAIL,
  payload: error,
})



export const getPairs = (onFetch) => ({
  type: GET_PAIRS,
  onFetch
})

export const getPairsSuccess = pairs => ({
  type: GET_PAIRS_SUCCESS,
  payload: pairs,
})

export const getPairsFail = error => ({
  type: GET_PAIRS_FAIL,
  payload: error,
})



export const createBot = (bot, onSuccess, onError) => ({
  type: CREATE_BOT,
  payload: bot,
  onSuccess,
  onError
})

export const createBotSuccess = bot => ({
  type: CREATE_BOT_SUCCESS,
  payload: bot,
})

export const createBotFail = error => ({
  type: CREATE_BOT_FAIL,
  payload: error,
})
export const editBot = (bot, onSuccess, onError) => ({
  type: EDIT_BOT,
  payload: bot,
  onSuccess,
  onError
})

export const editBotSuccess = bot => ({
  type: EDIT_BOT_SUCCESS,
  payload: bot,
})

export const editBotFail = error => ({
  type: EDIT_BOT_FAIL,
  payload: error,
})



export const startStopBot = (bot, onSuccess, onError) => ({
  type: START_STOP_BOT,
  payload: bot,
  onSuccess,
  onError
})

export const startStopBotSuccess = bot => ({
  type: START_STOP_BOT_SUCCESS,
  payload: bot,
})

export const startStopBotFail = error => ({
  type: START_STOP_BOT_FAIL,
  payload: error,
})



export const updateBot = (bot, onSuccess, onError) => ({
  type: UPDATE_BOT,
  payload: bot,
  onSuccess,
  onError
})

export const updateBotSuccess = bot => ({
  type: UPDATE_BOT_SUCCESS,
  payload: bot,
})

export const updateBotFail = error => ({
  type: UPDATE_BOT_FAIL,
  payload: error,
})



export const deleteBot = (bot, onSuccess, onError) => ({
  type: DELETE_BOT,
  payload: bot,
  onSuccess,
  onError
})

export const deleteBotSuccess = bot => ({
  type: DELETE_BOT_SUCCESS,
  payload: bot,
})

export const deleteBotFail = error => ({
  type: DELETE_BOT_FAIL,
  payload: error,
})



export const getOpenOrders = (id, onSuccess, onError) => ({
  type: GET_OPEN_ORDERS,
  payload: id,
  onSuccess,
  onError
})

export const getOpenOrdersSuccess = (orders, botID) => ({
  type: GET_OPEN_ORDERS_SUCCESS,
  payload: orders,
  botID: botID
})

export const getOpenOrdersFail = error => ({
  type: GET_OPEN_ORDERS_FAIL,
  payload: error,
})

export const getSelectedCoinBal = (id, onSuccess, onError) => ({
  type: GET_SELECTED_COIN_BAL,
  payload: id,
  onSuccess,
  onError
})
export const getSelectedCoinBalSuccess = (orders, botID) => ({
  type: GET_SELECTED_COIN_BAL_SUCCESS,
  payload: orders,
  botID: botID
})

export const getSelectedCoinBalFail = error => ({
  type: GET_SELECTED_COIN_BAL_FAIL,
  payload: error,
})
