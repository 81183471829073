import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import { Link } from "react-router-dom"
import {
  Alert,
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
  Input,
  Modal
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "navigation/Breadcrumb"

import QRious from 'qrious';

import {
  enable2fa,
  verify2fa,
  disable2fa,
  toggle2faSuccess,
  changePassword,
} from 'store/actions'


class Security extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading2fa: false,
      loadingChangePassword: false,

      success2fa: null,
      error2fa: null,
      copySuccess: null,
      copyError: null,
      successChangePassword: null,
      errorChangePassword: null,

      awaitingVerification: false,
      disable2faModalVisible: false,
      disableVerify2FAModalVisible: false,

      // 'ORSXG5DJNZTTEZTB', // base32 for 'testing2fa'
      secret: '',
      authCode: '',
      token_code: '',

      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',

      currentPasswordVisible: false,
      newPasswordVisible: false,
      confirmNewPasswordVisible: false,
    }

    this.disable2faModal = this.disable2faModal.bind(this)
    this.toggleDisable2faModal = this.toggleDisable2faModal.bind(this)
    this.toggleDisableVerify2FAModal = this.toggleDisableVerify2FAModal.bind(this)

    this.enable2fa = this.enable2fa.bind(this)
    this.enable2faSuccess = this.enable2faSuccess.bind(this)
    this.enable2faError = this.enable2faError.bind(this)

    this.verifyCode = this.verifyCode.bind(this)
    this.verify2faSuccess = this.verify2faSuccess.bind(this)
    this.verify2faError = this.verify2faError.bind(this)

    this.disableVerify2FA = this.disableVerify2FA.bind(this)
    this.disable2fa = this.disable2fa.bind(this)
    this.disable2faSuccess = this.disable2faSuccess.bind(this)
    this.disable2faError = this.disable2faError.bind(this)

    this.onClickCopy = this.onClickCopy.bind(this)

    this.changePassword = this.changePassword.bind(this)
    this.changePasswordSuccess = this.changePasswordSuccess.bind(this)
    this.changePasswordError = this.changePasswordError.bind(this)
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  enable2fa() {
    this.setState({ awaitingVerification: true })
    this.props.onEnable2FA(this.enable2faSuccess, this.enable2faError)
  }

  enable2faSuccess(response) {
    if (!this.props.user?.email) {
      this.setState({ error2fa: 'Cannot find email for user.' })
      return;
    }

    let email = this.props.user?.email
    let secret = response.data.secret
    let uri = `otpauth://totp/Talmud%20Trade:${email}?secret=${secret}&issuer=Talmud%20Trade&algorithm=SHA1&digits=6&period=30`
    let qr = new QRious({
      element: document.getElementById('qr-code-2fa'),
      value: uri
    })

    this.setState({
      loading2fa: false,
      secret
    })
  }

  enable2faError(response) {
    this.setState({
      error2fa: response.msg,
      loading2fa: false,
    })
  }

  verifyCode() {
    this.setState({ loading2fa: true })
    this.props.onVerify2FA(this.state.authCode, this.verify2faSuccess, this.verify2faError)
  }

  verify2faSuccess(response) {
    this.setState({
      loading2fa: false,
      success2fa: 'Code Verified' // response.msg
    })

    this.props.onToggle2FASuccess()

    setTimeout(() => {
      this.setState({ awaitingVerification: false })
    }, 1000)
  }

  verify2faError(response) {
    this.setState({
      loading2fa: false,
      error2fa: 'Invalid Code' // response.data?.msg
    })
  }

  disable2fa() {
    this.setState({
      disable2faModalVisible: false,
      disableVerify2FAModalVisible: true
    })
  }

  disableVerify2FA() {
    this.setState({
      disableVerify2FAModalVisible: false,
      loading2fa: true
    })
    let params = {
      token_code: this.state.token_code
    }
    this.props.onDisable2FA(params, this.disable2faSuccess, this.disable2faError)

  }

  disable2faSuccess(response) {
    this.setState({
      loading2fa: false,
      success2fa: response.msg
    })
  }

  disable2faError(response) {
    this.setState({
      loading2fa: false,
      error2fa: response.data?.msg
    })
  }

  onClickCopy() {
    let input = document.getElementById('secret');
    if (!input) {
      this.setState({ copyError: 'Problem copying to clipboard. Please copy manually.' })
      return;
    }
    input.focus();
    input.select();
    document.execCommand('copy', false);
    input.blur();
    window.getSelection().removeAllRanges();

    this.setState({ copySuccess: 'Copied to clipboard.' })
  }


  changePassword() {
    const { currentPassword, newPassword, confirmNewPassword } = this.state

    if (newPassword !== confirmNewPassword) {
      this.setState({ errorChangePassword: 'New Passwords do not match.' })
      return;
    }

    if (!newPassword.match(/[A-Z]/) ||
        !newPassword.match(/[a-z]/) ||
        !newPassword.match(/[0-9]/) ||
        !newPassword.match(/[!@#$%^&*()_+=-?]/)) {
      this.setState({ errorChangePassword: 'Password must include an uppercase and lowercase letter, a number, and a special character.' })
      return;
    }

    this.setState({
      errorChangePassword: null,
      loadingChangePassword: true
    })

    let params = {
      oldPassword: currentPassword,
      newPassword: newPassword
    }

    this.props.onChangePassword(params, this.changePasswordSuccess, this.changePasswordError)
  }

  changePasswordSuccess(response) {
    this.setState({
      loadingChangePassword: false,
      successChangePassword: response?.msg,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    })
  }
  changePasswordError(response) {
    this.setState({
      loadingChangePassword: false,
      errorChangePassword: response?.data?.msg
    })
  }



  toggleDisable2faModal() {
    this.setState({ disable2faModalVisible: !this.state.disable2faModalVisible })
  }

  toggleDisableVerify2FAModal() {
    this.setState({ disableVerify2FAModalVisible: !this.state.disableVerify2FAModalVisible })
  }

  disable2faModal() {
    return (
      <Modal
        isOpen={this.state.disable2faModalVisible}
        toggle={this.toggleDisable2faModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Disable 2FA</h5>
          <button
            type="button"
            onClick={this.toggleDisable2faModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <p>Disable Two Factor Authentication?</p>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleDisable2faModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.disable2fa}
          >
            Disable
            </button>
        </div>
      </Modal>
    )
  }

  disableVerify2FAModal() {
    return (
      <Modal
        isOpen={this.state.disableVerify2FAModalVisible}
        toggle={this.toggleDisableVerify2FAModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Two Factor Authentication</h5>
          <button
            type="button"
            onClick={this.toggleDisableVerify2FAModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">

          <p>Please enter your Google Authenticator code below to log in.</p>

          <Input
            type="number"
            className="form-control no-spinner"
            onInput={(e) => this.setState({ token_code: e.target.value })}
          />

          {this.state.error2fa && (
            <Alert color="danger" role="alert" className="mt-3">
              {this.state.error2fa}
            </Alert>
          )}

        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleDisableVerify2FAModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.disableVerify2FA}
            disabled={!this.state.token_code}
          >
          {this.state.loading ? (
            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
          ) : 'Submit'}
          </button>
        </div>
      </Modal>
    )
  }


  render() {
    const { t } = this.props;
    const {
      currentPasswordVisible,
      newPasswordVisible,
      confirmNewPasswordVisible,
      currentPassword,
      newPassword,
      confirmNewPassword,
      secret
    } = this.state;

    let is2faEnabled = this.props.user?.two_fa_enable;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Talmud | Security</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Security")} />

            {this.disable2faModal()}
            {this.disableVerify2FAModal()}

            <Row>
              <Col md="6">
                <Card>
                  <CardBody>
                    <div className="mb-4 h4 card-title" style={{justifyContent:"space-between",display:"flex", flexDirection:"row"}}>
                      <p>2FA</p>
                    </div>

                    {this.state.success2fa && (
                      <Alert color="success" role="alert">
                        {this.state.success2fa}
                      </Alert>
                    )}

                    {this.state.error2fa && (
                      <Alert color="danger" role="alert">
                        {this.state.error2fa}
                      </Alert>
                    )}

                    {this.state.awaitingVerification ? (
                      <React.Fragment>
                        <canvas id="qr-code-2fa" className="d-block mb-4 mx-auto"></canvas>

                        <ol style={{ paddingLeft: '16px' }}>
                          <li className="mb-2">Scan the QR code above or copy and paste the Authentication Key below into your Google Authenticator app.</li>
                          <li>Enter the 6-digit code displayed in the Google Authenticator app in the Authentication Code field below.</li>
                        </ol>

                        <div className="mb-3">
                          <Label>Authentication Key</Label>
                          <div className="input-group mb-3">
                            <Input type="text" id="secret" className="form-control" readOnly
                              value={secret}/>
                            <button type="button" className="btn btn-primary"
                              onClick={this.onClickCopy}
                              >Copy</button>
                          </div>
                        </div>

                        {this.state.copySuccess && (
                          <Alert color="success" role="alert">
                            {this.state.copySuccess}
                          </Alert>
                        )}

                        {this.state.copyError && (
                          <Alert color="danger" role="alert">
                            {this.state.copyError}
                          </Alert>
                        )}

                        <div className="mb-3">
                          <Label>Authentication Code</Label>
                          <Input type="number" className="form-control no-spinner"
                            onChange={(e) => this.setState({ authCode: e.target.value })}/>
                        </div>

                        <button type="submit"
                          className="btn btn-primary w-md mt-3"
                          disabled={!secret.trim()}
                          onClick={this.verifyCode}
                        >{this.state.loading2fa ? (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                        ) : 'Submit'}</button>
                      </React.Fragment>

                    ) : (

                      <React.Fragment>
                        {is2faEnabled ? (
                          <React.Fragment>
                            <div
                              className="bg-success bg-soft"
                              style={{
                                display: 'flex',
                                width: '100%',
                                height: '60px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                              <i className="bx bx-check-circle font-size-24 text-success" />
                              <p style={{ margin: 0, marginLeft: '0.5rem' }}>Enabled</p>
                            </div>

                            <button type="submit"
                              className="btn btn-primary w-md mt-3"
                              onClick={this.toggleDisable2faModal}
                            >{this.state.loading2fa ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                            ) : 'Disable 2FA'}</button>
                          </React.Fragment>

                        ) : (

                          <React.Fragment>
                            <div
                              className="bg-danger bg-soft"
                              style={{
                                display: 'flex',
                                width: '100%',
                                height: '60px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                              <i className="bx bx-x-circle font-size-24 text-danger" />
                              <p style={{ margin: 0, marginLeft: '0.5rem' }}>Disabled</p>
                            </div>

                            <button type="submit"
                              className="btn btn-primary w-md mt-3"
                              onClick={this.enable2fa}
                            >{this.state.loading2fa ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                            ) : 'Enable 2FA'}</button>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </CardBody>
                </Card>
              </Col>



              <Col md="6">
                <Card>
                  <CardBody>
                    <div className="mb-4 h4 card-title" style={{justifyContent:"space-between",display:"flex", flexDirection:"row"}}>
                      <p>Change Password</p>
                    </div>

                    <Label>Current Password</Label>
                    <div className="input-group mb-3">
                      <Input
                        type={currentPasswordVisible ? 'text' : 'password'}
                        className="form-control"
                        value={currentPassword}
                        onChange={(e) => this.setState({ currentPassword: e.target.value })}
                      />
                      <button type="button" className="btn btn-secondary"
                        onClick={() => this.setState({ currentPasswordVisible: !currentPasswordVisible })}
                        >{currentPasswordVisible ? 'Hide' : 'Show'}</button>
                    </div>

                    <Label>New Password</Label>
                    <div className="input-group mb-3">
                      <Input
                        type={newPasswordVisible ? 'text' : 'password'}
                        className="form-control"
                        value={newPassword}
                        onChange={(e) => this.setState({ newPassword: e.target.value })}
                      />
                      <button type="button" className="btn btn-secondary"
                        onClick={() => this.setState({ newPasswordVisible: !newPasswordVisible })}
                        >{newPasswordVisible ? 'Hide' : 'Show'}</button>
                    </div>

                    <Label>Confirm New Password</Label>
                    <div className="input-group mb-3">
                      <Input
                        type={confirmNewPasswordVisible ? 'text' : 'password'}
                        className="form-control"
                        value={confirmNewPassword}
                        onChange={(e) => this.setState({ confirmNewPassword: e.target.value })}
                      />
                      <button type="button" className="btn btn-secondary"
                        onClick={() => this.setState({ confirmNewPasswordVisible: !confirmNewPasswordVisible })}
                        >{confirmNewPasswordVisible ? 'Hide' : 'Show'}</button>
                    </div>

                    {this.state.successChangePassword && (
                      <Alert color="success" role="alert">
                        {this.state.successChangePassword}
                      </Alert>
                    )}

                    {this.state.errorChangePassword && (
                      <Alert color="danger" role="alert">
                        {this.state.errorChangePassword}
                      </Alert>
                    )}

                    <button type="submit"
                      className="btn btn-primary w-md mt-3"
                      onClick={this.changePassword}
                      disabled={
                        !currentPassword.trim() ||
                        !newPassword.trim() ||
                        !confirmNewPassword.trim()
                      }
                    >{this.state.loadingChangePassword ? (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                    ) : 'Submit'}</button>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Security.propTypes = {
  t: PropTypes.any,
  user: PropTypes.object,
  onEnable2FA: PropTypes.func,
  onVerify2FA: PropTypes.func,
  onDisable2FA: PropTypes.func,
  onToggle2FASuccess: PropTypes.func,
  onChangePassword: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.auth.user
})

const mapDispatchToProps = dispatch => ({
  onEnable2FA: (onSuccess, onError) => dispatch(enable2fa(onSuccess, onError)),
  onVerify2FA: (code, onSuccess, onError) => dispatch(verify2fa(code, onSuccess, onError)),
  onDisable2FA: (params, onSuccess, onError) => dispatch(disable2fa(params, onSuccess, onError)),
  onToggle2FASuccess: () => dispatch(toggle2faSuccess()),
  onChangePassword: (params, onSuccess, onError) => dispatch(changePassword(params, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Security)))
