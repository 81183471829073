import axios from "axios"
// import accessToken from "./jwt-token-access/accessToken"


// Dev
// const API_URL = "https://fcc9-103-5-18-130.in.ngrok.io/"

// Testing
// const API_URL = "http://128.199.129.139:9047/"

// Production
const API_URL = "https://api.talmud.trade/"



const axiosApi = axios.create({
  baseURL: API_URL + 'api/'
})


// ------------------
// STUB

export function stub(response, ms, isError) {
  return new Promise((resolve, reject) => {
    if (isError) {
      setTimeout(() => {
        reject(response)
      }, ms)
    }
    else {
      setTimeout(() => {
        resolve(response)
      }, ms)
    }
  })
}


// ------------------
// TOKEN

export function setToken(token) {
  axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + token;
}

if (window.localStorage.getItem('token')) {
  setToken(window.localStorage.getItem('token'))
}

// ------------------


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function apiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}


export const recaptchaKey = '6LeZh4MUAAAAAGYX7WUwi1Dji8khGPAJoVl7nB4Q'
